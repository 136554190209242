import Vue from 'vue';
import idb from './indexedDB/index.js';
import validateError from './mixins/validateError';

function getUserAgent() {
    let userAgent = window && window.cordova && window.cordova.platformId && window.cordova.platformId.toUpperCase() + "; " || "";
    userAgent += window && window.navigator && window.navigator.userAgent;

    return userAgent;
}

export default {
    state: {
        user: {},
        isDev: false,
        exchangeRates: [],
        currencyItems: [
            {
                text: 'Доллар',
                value: 'USD',
                sign: 'fal fa-dollar-sign'
            },
            {
                text: 'Евро',
                value: 'EUR',
                sign: 'fal fa-euro-sign'
            },
            {
                text: 'Рубль',
                value: 'RUB',
                sign: 'fal fa-ruble-sign'
            },
            {
                text: 'Гривна',
                value: 'UAH',
                sign: 'fal fa-hryvnia'
            },
            {
                text: 'Злотый',
                value: 'PLN',
                alias: 'zł'
            },
            {
                text: 'Белорусский рубль',
                value: 'BYN',
                alias: 'Br'
            },
            {
                text: 'Тенге',
                value: 'KZT',
                sign: 'fal fa-tenge'
            },
            {
                text: 'Фунт стерлингов',
                value: 'GBP',
                sign: 'fal fa-pound-sign'
            },
        ]
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user.email;
        },
        isDev(state) {
            return state.isDev;
        },
        currentUser(state) {
            return state.user;
        },
        isOldUser(state) {
            return new Date() - new Date(state.user.createdAt) > 30*24*60*60*1000;
        },
        getExchangeRates(state) {
            return state.exchangeRates;
        },
        getReadableRates(state) {
            if (state.user.currency === 'UAH') {
                return '$' + Number(state.exchangeRates[state.user.currency].rates.USD.buy).toFixed(2) + ' / ' +
                    Number(state.exchangeRates[state.user.currency].rates.USD.sale).toFixed(2);
            }
        },
        getCurrencyItems(state) {
            return state.currencyItems;
        }
    },
    actions: {
        async initialLoad({ commit }) {
            // fetch("https://ru.sxgeo.city/json?lng=en")
            //     .then((response) => {
            //         return response.json();
            //     })
            //     .then((data) => {
            //         let isDev = data.ip === "";
            //         commit("IS_DEV", isDev);
            //     }).catch((error) => {
            //         console.log(error);
            //     });

            if (!localStorage.auth) return false; // if auth-token doesn't exist
            if (!navigator.onLine) { // if app is offline
                let user = await idb.getUser();
                commit("CURRENT_USER_FETCHED", user || {});
                return false;
            }

            try {
                const res = await Vue.axios.get("/api/user/currentUser");
                if (res.data.deleted) {
                    await this.logOut();
                    return;
                }

                commit("CURRENT_USER_FETCHED", res.data);
                await idb.saveUser(res.data); // save to Cash
            } catch (error) {
                let user = await idb.getUser();
                commit("CURRENT_USER_FETCHED", user || {});
            }
        },
        async loadExchangeRates({ commit, state }) {
            let exchangeRates;

            if (!navigator.onLine) { // if app is offline
                exchangeRates = await idb.getRates();
                commit("RATES_FETCHED", exchangeRates);
                return false;
            }
            try {
                let res = await Vue.axios.get("/api/rates/" + (state.user.currency || 'USD'), { timeout: 3500 });
                exchangeRates = res.data;

                await idb.saveRates({ id: 1, exchangeRates });
            } catch (err) {
                exchangeRates = await idb.getRates();
            }
            commit("RATES_FETCHED", exchangeRates);
        },
        async logIn({ commit }, [ email, password, code ] ) {
            try {
                const res = await Vue.axios.get("/api/user/login", { params: { email, password, code, userAgent: getUserAgent() } });
                if (res.data.error) throw res.data.error;

                commit("CURRENT_USER_FETCHED", res.data);
                localStorage.auth = res.headers.auth;
                await idb.saveUser(res.data);
            } catch (error) {
                throw validateError(error);
            }
        },
        async logOut({ state, commit }) {
            await Promise.all([
                idb.deleteOperations(),
                idb.deleteDebts(),
                idb.deleteAccount(),
                idb.deleteSpend(),
                idb.deleteIncome(),
                idb.deleteUser(state.user)
            ]);
            commit('LOG_OUT');
        },
        async register({ state, commit }, [ email, password, password2, tokenId ] ) {
            // check user ip and timezone
            let ip = null, timezone = null, currency, userAgent = getUserAgent();

            try {
                let res = await Vue.axios.get("https://ru.sxgeo.city/json?lng=en");
                ip = res.data.ip;
                timezone = res.data.country.timezone;
                currency = res.data.country.cur_code;
            } catch (error) {
                console.log(error);
            }

            currency = state.currencyItems.find(supportedCurrency => supportedCurrency.value === currency)?.value || 'USD';

            try {
                const res = await Vue.axios.post("/api/user/register", { email, password, password2, ip, timezone, currency, tokenId, userAgent });
                if (res.data.error) throw res.data.error;

                commit("CURRENT_USER_FETCHED", res.data);
                localStorage.auth = res.headers.auth;
                await idb.saveUser(res.data);
            } catch (error) {
                throw validateError(error);
            }
        },
        async updateUser({ state, commit }, newUserData) {
            try {
                const res = await Vue.axios.post("/api/user/updateUser", { user: newUserData });
                await idb.deleteUser(state.user);
                commit('CURRENT_USER_FETCHED', res.data);
                await idb.saveUser(res.data);
            } catch (error) {
                throw validateError(error);
            }
        },
        async forgetPass(context, email) {
            try {
                const res = await Vue.axios.get("/api/user/forgetpass", { params: { email } });
                if (res.data.error) throw res.data.error;
            } catch (error) {
                throw validateError(error);
            }
        },
        async restorePass({ commit }, { token, newPassword }) {
            try {
                const res = await Vue.axios.get("/api/user/restorepass", { params: { token, newPassword } });
                if (res.data.error) throw res.data.error;

                commit("CURRENT_USER_FETCHED", res.data);
                localStorage.auth = res.headers.auth;
                await idb.saveUser(res.data);
            } catch (error) {
                throw validateError(error);
            }
        },
        async deleteUser({ state, commit }) {
            try {
                await Vue.axios.delete("/api/user/deleteUser");
                commit('LOG_OUT');
                await idb.deleteUser(state.user);
            } catch (error) {
                throw validateError(error);
            }
        }
    },
    mutations: {
        CURRENT_USER_FETCHED(state, user) {
            state.user = user;
        },
        RATES_FETCHED(state, exchangeRates) {
            state.exchangeRates = exchangeRates;
        },
        LOG_OUT() {
            localStorage.removeItem('auth');
        },
        IS_DEV(state, isDev) {
            state.isDev = isDev;
        }
    }
}