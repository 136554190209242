<template>
    <v-dialog v-model="isDialog" persistent>
        <h3>{{ title }}</h3>
        <v-radio-group
            v-if="typeof description === 'object'"
            v-model="accountIndex"
            class="fieldset"
        >
            <v-radio
                v-for="(account, i) of description"
                :key="i"
                :label="(account.name ? account.name : 'Mono') + ' (' + account.balance + ' ' + account.currency + ')'"
                class="flex-grow-1"
                :value="i"
            ></v-radio>
        </v-radio-group>
        <p v-else>{{ description }}</p>
        <div class="buttons">
            <span class="cancel" @click="cancel">Нет</span>
            <span class="approved" @click="approved">Да</span>
        </div>
    </v-dialog>
</template>

<script>
import EventBus from '@/event-bus';

export default {
    name: 'vue-dialog',
    data() {
        return {
            isDialog: false,
            title: '',
            description: '',
            cancelCallback: () => ({}),
            approvedCallback: () => ({}),
            accountIndex: 0
        }
    },
    created() {
        EventBus.$on('SHOW_DIALOG', (data) => {
            this.showDialog(data)
        });
    },
    methods: {
        cancel() {
            this.cancelCallback();
            this.closeForm();

        },
        approved() {
            this.approvedCallback(this.accountIndex);
            this.closeForm();
        },
        showDialog({ title, description, cancelCallback, approvedCallback, accountIndex}) {
            this.title = title || this.title;
            this.description = description || this.description;
            this.cancelCallback = cancelCallback || this.cancelCallback;
            this.approvedCallback = approvedCallback || this.approvedCallback;
            this.accountIndex = accountIndex || this.accountIndex;
            this.isDialog = true;
        },
        closeForm() {
            this.isDialog = false;
            this.title = '';
            this.description = '';
            this.cancelCallback = () => ({});
            this.approvedCallback = () => ({});
            this.accountIndex = 0;
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/vars.scss";

    .v-application .v-dialog {
        font-family: $f-family;
        font-size: $f-size;
        color: $text-regular;
        background-color: #fff;
        margin: 15px;
        padding: 20px;
        max-width: 420px;

        h3 {
            font-size: 20px;
            font-weight: normal;
            color: $text;
            margin-bottom: 20px;
        }

        p, .v-input.fieldset {
            color: $text-regular;
            margin-bottom: 10px;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            font-size: 20px;

            span {
                text-transform: capitalize;
                padding: 6px 30px;
                margin-left: 10px;
                border-radius: 20px;
                cursor: pointer;

                &.cancel {
                    color: $text-regular;
                }
                &.approved {
                    color: $text;
                    border: 1px solid $text;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .v-application .v-dialog {
            h3, .buttons {
                font-size: 16px;
            }
        }
    }
</style>