import Vue from 'vue';
import idb from './indexedDB/index.js';
import router from '../router';
import validateError from './mixins/validateError';

function delay (time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export default {
    actions: {
        async yandexAuth({ rootState, commit, dispatch }, query) {
            let code = query.code, categoryId = query.categoryId;
            try {
                const res = await Vue.axios.post("/api/banks/yandex", { code });
                if (res.data.error) throw res.data.error;

                if (res.data.access_token) {
                    await dispatch("updateUser", { yandex: res.data.access_token });

                    const accInfo = await Vue.axios.post("/api/banks/yandexAcc", { token: res.data.access_token });

                    if (categoryId) { // if yandex account imported to existing account
                        let accToEdit = rootState.Categories.accounts.filter(account => account.id === categoryId)[0];
                        accToEdit = {
                            ...accToEdit,
                            categoryId,
                            currency: 'RUB',
                            balance: accInfo.data.balance,
                            import: 'yandex',
                            cardNumber: accInfo.data.account
                        }

                        const editedAccData = await Vue.axios.post("/api/categories/edit/account/", accToEdit);
                        commit("CATEGORY_EDITED", [ editedAccData.data, 'account' ]);
                    } else {
                        let newAccount = {
                            name: 'ЮMoney',
                            currency: 'RUB',
                            balance: accInfo.data.balance,
                            isHidden: false,
                            accType: 'self',
                            import: 'yandex',
                            cardNumber: accInfo.data.account
                        }
                        const accData = await Vue.axios.post("/api/categories/add/account", newAccount);
                        commit("CATEGORY_ADDED", [ accData.data, 'account' ]);
                        categoryId = accData.data.id;
                    }
                    router.push('/category/settings/account/' + categoryId);
                }
            } catch (error) {
                throw validateError(error);
            }
        },
        async monobankAuth({ rootState, commit }) {
            try {
                const res = await Vue.axios.get("/api/banks/monobank");
                if (res.data.error) throw res.data.error;

                if (res.data.monobank) {
                    await idb.deleteUser(rootState.User.user);
                    commit('CURRENT_USER_FETCHED', res.data);
                    await idb.saveUser(res.data);
                }
            } catch (error) {
                throw validateError(error);
            }
        },
        async checkMonobankAuth({ rootState, commit, dispatch }, id) {
            try {
                // if rootState.User.user.monobank === string (link) - verify user, else if not true - throw error
                if (typeof rootState.User.user.monobank === 'string') {
                    const res = await Vue.axios.get("/api/banks/monobankcheck");
                    if (res.data.error) {
                        if (res.data.error.indexOf('Request not found') >= 0) {
                            throw 'Ссылка устарела! Отвяжите monobank от аккаунта и запросите новую ссылку.';
                        }

                        throw res.data.error;
                    }

                    if (!res.data.monobank) { throw res.data }

                    await idb.deleteUser(rootState.User.user);
                    commit('CURRENT_USER_FETCHED', res.data);
                    await idb.saveUser(res.data);
                } else if (!rootState.User.user.monobank) {
                    throw 'Ссылка устарела! Отвяжите monobank от аккаунта и запросите новую ссылку.';
                }

                if (id) {
                    const res = await Vue.axios.get("/api/banks/monobankaccs");
                    if (res.data.error) throw res.data.error;

                    let newAccounts = [];
                    res.data._accounts.forEach(account => {
                        let balance = (account._creditLimit) ?
                            (account._balance - account._creditLimit) / Math.pow(10, account._currencyCode.digits) :
                            account._balance / Math.pow(10, account._currencyCode.digits);
                        newAccounts.push({
                            name: 'Mono (' + account._currencyCode.code + ')',
                            currency: account._currencyCode.code,
                            balance: balance,
                            isHidden: false,
                            accType: 'self',
                            import: 'monobank',
                            cardNumber: account._id
                        });
                    });

                    return newAccounts;
                } else {
                    await dispatch('addMonobankAccounts');
                }
            } catch (error) {
                throw validateError(error);
            }
        },
        async addMonobankAccounts({ rootState, commit }) {
            try {
                const res = await Vue.axios.get("/api/banks/monobankaccs");
                if (res.data.error) throw res.data.error;

                let newAccounts = [];
                res.data._accounts.forEach(account => {
                    let isCardAlredyAdded = rootState.Categories.accounts.some(category => account._id === category.cardNumber);

                    if (!isCardAlredyAdded) {
                        let balance = (account._creditLimit) ?
                            (account._balance - account._creditLimit) / Math.pow(10, account._currencyCode.digits) :
                            account._balance / Math.pow(10, account._currencyCode.digits);

                        newAccounts.push({
                            name: 'Mono (' + account._currencyCode.code + ')',
                            currency: account._currencyCode.code,
                            balance: balance,
                            isHidden: false,
                            accType: 'self',
                            import: 'monobank',
                            cardNumber: account._id
                        });
                    }
                });

                if (!newAccounts.length) {
                    throw "У вас уже добавлены все карты! (Счета ФЛП пока что не поддерживаются для импорта)";
                }

                let categoryId;
                for (let [i, account] of newAccounts.entries()) {
                    if (i) await delay(1001);
                    const accData = await Vue.axios.post("/api/categories/add/account", account);
                    commit("CATEGORY_ADDED", [ accData.data, 'account' ]);
                    categoryId = accData.data.id;
                }

                router.push('/category/settings/account/' + categoryId);
            } catch (error) {
                throw validateError(error);
            }
        },
        async checkYandexBalance({ dispatch }, yandexAccount) {
            const accInfo = await Vue.axios.post("/api/banks/yandexAcc", { token: null });

            if (accInfo.data.balance !== yandexAccount.balance) {
                await dispatch('editCategory', { ...yandexAccount, categoryName: 'account', balance: accInfo.data.balance });
            }
        },
        async checkMonobankBalance({ dispatch }, dbAccount) {
            const res = await Vue.axios.get("/api/banks/monobankaccs");
            if (res.data.error) throw res.data.error;

            let newBalance = dbAccount.balance;
            res.data._accounts.forEach(account => {
                if (dbAccount.cardNumber === account._id) {
                    newBalance = (account._creditLimit) ?
                        (account._balance - account._creditLimit) / Math.pow(10, account._currencyCode.digits) :
                        account._balance / Math.pow(10, account._currencyCode.digits);
                }
            });

            if (newBalance !== dbAccount.balance) {
                await dispatch('editCategory', { ...dbAccount, categoryName: 'account', balance: newBalance });
            }
        },
        async checkYandexOperations() {
            try {
                const res = await Vue.axios.get("/api/banks/yandexOperations");
                if (res.data.error) throw res.data.error;

                return res.data;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async checkMonobankOperations(context, cardNumber) {
            try {
                const res = await Vue.axios.post("/api/banks/monobankOperations", { cardNumber });
                if (res.data.error) throw res.data.error;

                return res.data;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async unlinkYandex({ rootState, dispatch }) {
            rootState.Categories.accounts.forEach(async (account) => {
                if (account.import === 'yandex') {
                    await dispatch('editCategory', { ...account, categoryName: 'account', import: null, cardNumber: null });
                }
            });
            await dispatch("updateUser", { yandex: null });
        },
        async unlinkMonobank({ rootState, dispatch }) {
            rootState.Categories.accounts.forEach(async (account) => {
                if (account.import === 'monobank') {
                    await dispatch('editCategory', { ...account, categoryName: 'account', import: null, cardNumber: null });
                }
            });
            await dispatch("updateUser", { monobank: null });
        },
    },
}