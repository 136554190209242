import EventBus from '@/event-bus';

export default {
    methods: {
        $getCurrencySign: function ( currency ) {
            switch ( currency ? currency : this.$store.getters.currentUser.currency ) {
                case 'UAH':
                    return 'грн';
                case 'USD':
                    return '$';
                case 'RUB':
                    return 'руб';
                case 'EUR':
                    return '€';
                case 'PLN':
                    return 'zł';
                case 'BYN':
                    return 'р (бел.)';
                case 'KZT':
                    return 'тңг';
                case 'ILS':
                    return '₪';
                case 'GBP':
                    return '£';
                default:
                    return 'у.е.';
            }
        },
        $convertToDefultCurrency: function (actualCurrency, amount, defaultCurr, context) {
            let convertedAmount = Number(amount);

            // depends, where functions was called:
            let state = context || this.$store.state.User;

            // if third parammetr have been added - convert to this currency instead currentUser.currency
            let convertToCurrency = defaultCurr || state.user.currency;

            // if currency of this amount is default - return without changes
            if (convertToCurrency === actualCurrency) return convertedAmount;

            // else convert
            if (convertToCurrency === 'UAH') {
                return convertedAmount * (state.exchangeRates.UAH.rates[actualCurrency].buy || 1);
            } else if (actualCurrency === 'UAH') {
                return convertedAmount / (state.exchangeRates.UAH.rates[convertToCurrency].sale || 1);
            } else {
                return convertedAmount * (state.exchangeRates[actualCurrency].rates[convertToCurrency] || 1);
            }
        },
        $showConfirmDialog: function (data) {
            EventBus.$emit('SHOW_DIALOG', data);
        }
    }
}