<template>
    <div class="menu-mobile">
        <router-link class="menu-mobile__item" to="/dashboard">
            <i class="far fa-wallet"></i>
            <span>Счета</span>
        </router-link>
        <router-link class="menu-mobile__item" to="/">
            <i class="far fa-sort-alt"></i>
            <span>Операции</span>
        </router-link>
        <div class="menu-mobile__item" @click="openAddOperationForm">
            <v-btn
                class="menu-mobile__item-add-operation"
                fab
                large
                elevation="2"
                plain
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <router-link class="menu-mobile__item" to="/debts">
            <i class="far fa-money-bill-wave"></i>
            <span>Долги</span>
        </router-link>
        <div class="menu-mobile__item">
            <Calendar/>
        </div>
    </div>
</template>

<script>
import Calendar from '@/components/Calendar';
import EventBus from '@/event-bus';

export default {
    name: 'MenuMobile',
    components: {
        Calendar,
    },
    data() {
        return {
            isMenuOpened: false,
        }
    },
    methods: {
        openAddOperationForm() {
            if (this.$route.name !== "finances" && this.$route.name !== "debts") {
                this.$router.push({ name: 'finances', params: { openForm: true } });
            } else {
                EventBus.$emit('OPEN_ADD_OPERATION_FORM');
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    .menu-mobile {
        position: fixed;
        bottom: 0;
        display: none;
        justify-content: space-around;
        align-items: center;
        background: #fff;
        width: 100%;
        box-shadow: $shadow-big;
        z-index: 999;

        @media screen and (max-width: 768px) {
            display: flex;
        }

        &__item {
            position: relative;
            text-decoration: none;
            padding: 10px 0;
            color: $text-regular;
            min-width: 64px;
            text-align: center;

            &.router-link-exact-active {
                color: $main;
            }

            & &-add-operation {
                position: absolute;
                outline: none;
                top: -30px;
                transform: translateX(-50%);
                color: $main;

                i {
                    margin-bottom: 0;
                }
            }

            i {
                display: block;
                text-align: center;
                margin-bottom: 5px;
                font-size: 20px;
            }
        }
    }
</style>
