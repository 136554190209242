import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#3E2BCE',
                secondary: '#75849A',
                accent: '#20509E',
                error: '#f75a56',
                info: '#2196F3',
                success: '#2dd3aa',
                warning: '#F9A825',
                orange: '#F9A825'
            },
        },
    },
});
