import Vue from 'vue';
import Router from 'vue-router';
import MainContainer from '@/pages/MainContainer.vue';
import Home from '@/views/Home.vue';
import Finances from '@/views/Finances.vue';
import LogIn from '@/components/LogIn.vue';
import Dashboard from '@/components/Dashboard.vue';
import iOSPWAGuide from '@/pages/Landing/iOSPWAGuide.vue';

Vue.use(Router);

function checkAuth(to, from, next) {
    if ( localStorage.getItem('auth') ) {
        next();
    } else {
        next('/login');
    }
}

export default new Router({
  mode: 'history',
  routes: [
    {
        path: '/',
        component: MainContainer,
        children: [
            {
                path: '',
                component: Home,
                children: [
                    {
                        path: '',
                        name: 'finances',
                        component: Finances,
                    },
                    {
                        path: 'debts',
                        name: 'debts',
                        component: () => import(/* webpackChunkName: "debts"*/ "@/views/Debts.vue"),
                        beforeEnter: checkAuth
                    }
                ]
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard,
                beforeEnter: checkAuth
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import(/* webpackChunkName: "profile"*/ "@/views/Profile.vue"),
                beforeEnter: checkAuth
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: () => import(/* webpackChunkName: "contacts"*/ "@/views/Contacts.vue"),
                beforeEnter: checkAuth
            },
            {
                path: '/category/',
                component: () => import(/* webpackChunkName: "category"*/ "@/views/Category.vue"),
                children: [
                    {
                        path: ':name/:id',
                        name: 'category',
                        component: Finances,
                    },
                    {
                        path: 'settings/:name/:id',
                        name: 'category-settings',
                        component: () => import(/* webpackChunkName: "category-settings"*/ "@/views/CategorySettings")
                    }
                ],
                beforeEnter: checkAuth
            },
            {
                path: 'add/:name',
                name: 'add-category',
                component: () => import(/* webpackChunkName: "category-settings"*/ "@/views/CategorySettings"),
                beforeEnter: checkAuth
            },
            {
                path: 'import/account',
                name: 'import-account',
                component: () => import(/* webpackChunkName: "import-account"*/ "@/views/CategoryImport"),
                beforeEnter: checkAuth
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LogIn,
    },
    {
        path: '/registration',
        name: 'registration',
        component: LogIn,
    },
    {
        path: '/foregetpass',
        name: 'foregetpass',
        component: LogIn,
    },
    {
        path: '/restorepass/:token',
        name: 'restorepass',
        component: LogIn,
    },
    {
        path: '/social-registration',
        name: 'social-registration',
        component: LogIn,
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "statistics"*/ "@/pages/Graphs.vue"),
        beforeEnter: checkAuth
    },
    {
        path: '/install-pwa-instructions',
        name: 'ios',
        component: iOSPWAGuide,
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "policy"*/ "@/pages/Landing/Policy.vue"),
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import(/* webpackChunkName: "terms"*/ "@/pages/Landing/Terms.vue"),
    },
    {
        path: '/*',
        redirect: '/'
    }
  ]
});
