const DB_NAME = 'stateCash';
const DB_VERSION = 1;
let DB;

export default async function () {

    return new Promise((resolve, reject) => {

        if (DB) { return resolve(DB); }

        let request = window.indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = e => {
            console.log('Error opening db', e);
            reject('Error');
        };

        request.onsuccess = e => {
            DB = e.target.result;
            resolve(DB);
        };

        request.onupgradeneeded = e => {
            console.log('onupgradeneeded');
            let db = e.target.result;
            db.createObjectStore("user", { autoIncrement: false, keyPath: 'email' });
            db.createObjectStore("exchangeRates", { autoIncrement: false, keyPath: 'id' });
            // db.createObjectStore("exchangeRates2", { autoIncrement: false, keyPath: 'id' });
            db.createObjectStore("accounts", { autoIncrement: true, keyPath: 'id' });
            db.createObjectStore("spending", { autoIncrement: true, keyPath: 'id' });
            db.createObjectStore("incoming", { autoIncrement: true, keyPath: 'id' });
            db.createObjectStore("operations", { autoIncrement: true, keyPath: 'id' });
            db.createObjectStore("debts", { autoIncrement: true, keyPath: 'id' });
        };
    });
}