<template>
    <div class="mainContainer">
        <Dashboard
            class="dashContainer"
            :class="{ show: isDashOpened }"
            v-on:closeDashClicked="isDashOpened = false"
        />
        <div class="content" :class="{ hide: isDashOpened }">
            <transition name="fade" mode="out-in">
                <router-view v-on:openDashClicked="isDashOpened = true" :key="$route.fullPath" />
            </transition>
        </div>
    </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';

export default {
    name: 'mainContainer',
    components: { Dashboard },
    data() {
        return {
            isDashOpened: false,
        }
    }
}
</script>

<style scoped lang="scss">

    .mainContainer {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 35px 65px 0 65px;
        .dashContainer {
            flex: 0 0 580px;
            max-width: 580px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-start;
        }
        .content {
            flex: 1;
            padding-left: 45px;
        }
    }

    @media screen and (max-width: 1750px) {
        .mainContainer {
            padding: 25px 25px 0 25px;
            .dashContainer {
                flex: 0 0 480px;
                max-width: 480px;
            }
            .content {
                padding-left: 25px;
            }
        }
    }

    @media screen and (max-width: 1400px) {
        .mainContainer {
            .dashContainer {
                display: none;
                flex: 0 0 100%;
                max-width: 100%;
                &.show {
                    display: flex;
                }
            }
            .content {
                padding-left: 0;
                &.hide {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .mainContainer {
            padding: 25px 30px 0 30px;
        }
    }

    @media screen and (max-width: 768px) {
        .mainContainer {
            padding: 25px 15px 0 15px;
        }
    }

    @media screen and (max-width: 610px) {
        .mainContainer {
            padding: 25px 0 0 0;
        }
    }
</style>

