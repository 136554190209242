import Vue from 'vue';
import idb from './indexedDB/index.js';
import validateError from './mixins/validateError';

export default {
    state: {
        debts: [],
        showReturned: false,
    },
    getters: {
        getDebts(state) {
            return (state.showReturned) ? state.debts : state.debts.filter( debt => !debt.isReturned );
        },
        getDebtsByDay(state) {
            let sortedByDay = [];
            let dates = new Set(); // collecton of unic dates
            let debts = (state.showReturned) ? state.debts : state.debts.filter( debt => !debt.isReturned );
            debts.sort((a, b) => new Date(b.date) - new Date(a.date)); // sort by date

            // make collection of dates
            debts.forEach((debt) => {
                dates.add(debt.date);
            });
            // make collection of arrays with unic operation date
            dates.forEach((date) => { // sort by time
                let debtsByDay = debts.filter((debt) => date === debt.date);
                debtsByDay.sort((a, b) => {
                    return (a.time.slice(0,2) === b.time.slice(0,2)) ? a.time.slice(3,5) - b.time.slice(3,5) : a.time.slice(0,2) - b.time.slice(0,2);
                });
                sortedByDay.push(debtsByDay);
            });

            return sortedByDay;
        },
        getReturnedDebts(state) {
            return { showReturned: state.showReturned, debts: state.debts.filter( debt => debt.isReturned ) };
        },
    },
    actions: {
        async loadDebts({ commit }) {
            if (!navigator.onLine) { // if app is offline
                let debts = await idb.getDebts();
                commit("DEBTS_FETCHED", debts || []);
                return false;
            }

            try {
                const res = await Vue.axios.get("/api/debts");
                commit("DEBTS_FETCHED", res.data.debts);
                await idb.saveDebts(res.data.debts);
            } catch (error) {
                let debts = await idb.getDebts();
                commit("DEBTS_FETCHED", debts || []);
            }
        },
        async addDebt({ commit, rootState }, debt) {
            try {
                const res = await Vue.axios.post("/api/debts/add", debt);
                commit("DEBT_ADDED", res.data);
                await idb.saveDebts([ res.data ]);
            } catch (error) {
                debt.cached = true;
                debt.cachedDate = debt.date;
                // fix date after added to caсhe (may cause a bug because of timezone)
                debt.date = new Date(debt.date).toISOString();
                let cachedDebt = await idb.saveDebts([ debt ]);
                commit("DEBT_ADDED", cachedDebt);
            }

            let accountToChange = rootState.Categories.accounts.find( account => account.id === debt.accId );
            if (!accountToChange) return; // if account exist
            let debtAbount = (debt.isDebtor) ? debt.amount : -debt.amount;
            accountToChange.balance = (Number(accountToChange.balance) - debtAbount).toFixed(2);

            try {
                const account = await Vue.axios.post("/api/categories/edit/account",
                    { ...accountToChange, categoryId: accountToChange.id }
                );
                commit("CATEGORY_EDITED", [ account.data, 'account' ]);
            } catch (error) {
                // if offline - update only state
                commit("CATEGORY_EDITED", [{ ...accountToChange, categoryId: accountToChange.id }, 'account' ]);
            }
        },
        async delDebt({ commit }, id) {
            try {
                await Vue.axios.delete("/api/debts/delete", { params: { id } });
                commit("DEBT_DELETED", id);
                await idb.deleteDebts(id);
            } catch (error) {
                // fix: doesn't work for offline
                throw validateError(error);
            }
        },
        async debtReturned({ commit, rootState }, debt) {
            try {
                await Vue.axios.post("/api/debts/returned", { id: debt.id });
                commit("DEBT_RETURNED", debt.id);
                debt.isReturned = true;
                await idb.saveDebts([ debt ]);
            } catch (error) {
                // fix: doesn't work for offline
                throw validateError(error);
            }

            let accountToChange = rootState.Categories.accounts.find( account => account.id === debt.accId );
            if (!accountToChange) return; // if account exist
            let debtAbount = (debt.isDebtor) ? -debt.amount : debt.amount;
            accountToChange.balance = (Number(accountToChange.balance) - debtAbount).toFixed(2);

            try {
                const account = await Vue.axios.post("/api/categories/edit/account",
                    { ...accountToChange, categoryId: accountToChange.id });
                commit("CATEGORY_EDITED", [ account.data, 'account' ]);
            } catch (error) {
                // if offline - update only state
                commit("CATEGORY_EDITED", [{ ...accountToChange, categoryId: accountToChange.id }, 'account' ]);
            }
        },
        async checkCachedDebts({ rootState }) {
            let debts = await idb.getDebts();
            let cachedDebts = debts.filter(debt => debt.cached);
            if (!cachedDebts.length) return false;

            for (let debt of cachedDebts) {
                try {
                    debt.date = debt.cachedDate;
                    const res = await Vue.axios.post("/api/debts/add", debt);
                    await idb.deleteDebts(debt.id);
                    await idb.saveDebts([ res.data ]);
                    let accountToChange = rootState.Categories.accounts.find( account => account.id === debt.accId );
                    await Vue.axios.post("/api/categories/edit/account", { ...accountToChange, categoryId: accountToChange.id });
                } catch (error) {
                    return false;
                }
            }
        }
    },
    mutations: {
        DEBTS_FETCHED (state, debts) {
            state.debts = debts;
        },
        DEBT_ADDED (state, debt) {
            state.debts.push(debt);
        },
        DEBT_DELETED (state, id) {
            state.debts = state.debts.filter( debt => debt.id !== id );
        },
        DEBT_RETURNED (state, id) {
            state.debts.forEach( debt => {
                if (debt.id === id) debt.isReturned = true;
            });
        },
        SHOW_RETURNED (state) {
            state.showReturned = !state.showReturned;
        }
    }
}
