import Vue from 'vue';
import idb from './indexedDB/index.js';
import mixins from '../mixins/mixins.js';
import validateError from './mixins/validateError';

export default {
    state: {
        accounts: [],
        showHiddenAccounts: false,
        spending: [],
        showHiddenSpending: false,
        incoming: [],
        showHiddenIncoming: false,
    },
    getters: {
        getAccounts(state) {
            return (state.showHiddenAccounts) ? state.accounts : state.accounts.filter((account) => !account.isHidden);
        },
        getAccountsAll(state) {
            return state.accounts;
        },
        isHiddenAccounts(state) {
            return state.accounts.filter((account) => account.isHidden).length;
        },
        showHiddenAccounts(state) {
            return state.showHiddenAccounts;
        },
        getSpending(state, getters, rootState) {
            let spending = state.spending.map(spend => {
                let operationsSpend = getters.getOperations.filter( operation => operation.to === 'spend' && operation.tid === spend.id );
                if (operationsSpend.length) {
                    spend.balance = -operationsSpend
                        .map(operation => {
                            // convert operations in other currency into main currency
                            return mixins.methods.$convertToDefultCurrency(operation.currency, operation.amount, spend.currency, rootState.User);
                        })
                        .reduce(( sum, amount ) => sum + amount ).toFixed(2);
                } else {
                    spend.balance = 0;
                }
                return spend;
            });
            return (state.showHiddenSpending) ? spending : spending.filter((spend) => !spend.isHidden);
        },
        getSpendingAll(state) {
            // !!! doesn't include balance of categories
            return state.spending;
        },
        isHiddenSpending(state) {
            return state.spending.filter((spend) => spend.isHidden).length;
        },
        showHiddenSpending(state) {
            return state.showHiddenSpending;
        },
        getIncoming(state, getters) {
            let incoming = state.incoming.map(income => {
                let operationsIncome = getters.getOperations.filter( operation => operation.from === 'income' && operation.fid === income.id );
                if (operationsIncome.length) {
                    income.balance = +operationsIncome
                        .map(operation => operation.amount)
                        .reduce(( sum, amount ) => sum + amount).toFixed(2);
                } else {
                    income.balance = 0;
                }
                return income;
            });
            return (state.showHiddenIncoming) ? incoming : incoming.filter((income) => !income.isHidden);
        },
        getIncomingAll(state) {
            // !!! doesn't include balance of categories
            return state.incoming;
        },
        isHiddenIncoming(state) {
            return state.incoming.filter((income) => income.isHidden).length;
        },
        showHiddenIncoming(state) {
            return state.showHiddenIncoming;
        }
    },
    actions: {
        async loadCategories({ commit, rootState }) {
            let res = { data: {} };
            if (!navigator.onLine) { // if app is offline
                res.data.accounts = await idb.getAccounts();
                res.data.spending = await idb.getSpending();
                res.data.incoming = await idb.getIncoming();
                commit("CATEGORIES_FETCHED", [ res.data.accounts, res.data.spending, res.data.incoming ]);
                return false;
            }

            try {
                res = await Vue.axios.get("/api/categories", { params: { dates: rootState.dates } });
                await idb.saveAccounts(res.data.accounts);
                await idb.saveSpending(res.data.spending);
                await idb.saveIncoming(res.data.incoming);
            } catch (error) {
                console.log(error);
                res.data.accounts = await idb.getAccounts();
                res.data.spending = await idb.getSpending();
                res.data.incoming = await idb.getIncoming();
            }
            commit("CATEGORIES_FETCHED", [ res.data.accounts, res.data.spending, res.data.incoming ]);
        },
        async getCategoryOwners({ commit }, [ id, uid, type ]) {
            const res = await Vue.axios.get("/api/categories/owners", { params: { id, uid, type } });
            commit("OWNERS_FETCHED", [ id, type, res.data.creator, res.data.accOwners ]);
        },
        async addCategory({ commit }, params) {
            try {
                const res = await Vue.axios.post("/api/categories/add/" + params.categoryName, params);
                commit("CATEGORY_ADDED", [ res.data, params.categoryName ]);
                // !: added to cash in commit
            } catch (error) {
                throw validateError(error);
            }
        },
        async editCategory({ commit }, params) {
            try {
                const res = await Vue.axios.post("/api/categories/edit/" + params.categoryName, params);
                commit("CATEGORY_EDITED", [ res.data, params.categoryName ]);
                // !: cash edited in commit
            } catch (error) {
                throw validateError(error);
            }
        },
        async delCategory({ commit }, params) {
            try {
                await Vue.axios.delete("/api/categories/delete", { params });
                commit("CATEGORY_DELETED", [ params.name, params.id ]);
                // !: cash deleted in commit
            } catch (error) {
                throw validateError(error);
            }
        },
        getCategoryName({ getters }, { category, id }) {
            let item = {};
            switch (category) {
                case 'account':
                    item = getters.getAccountsAll.find((account) => account.id === Number(id));
                    break;
                case 'spend':
                    item = getters.getSpendingAll.find((spend) => spend.id === Number(id));
                    break;
                case 'income':
                    item = getters.getIncomingAll.find((income) => income.id === Number(id));
                    break;
                default:
                    item.name = 'не задано';
            }
            if (!item) {
                item = { name: '-недоступно-' };
            }

            return item.name;
        }
    },
    mutations: {
        CATEGORIES_FETCHED (state, [ accounts, spending, incoming ]) {
            state.accounts = accounts;
            state.spending = spending;
            state.incoming = incoming;
        },
        CATEGORY_ADDED (state, [ category, categoryName ]) {
            switch (categoryName) {
                case 'account':
                    state.accounts.push(category);
                    idb.saveAccounts([ category ]);
                    break;
                case 'spend':
                    state.spending.push({ ...category, balance: 0 });
                    idb.saveSpending([ category ]);
                    break;
                case 'income':
                    state.incoming.push({ ...category, balance: 0 });
                    idb.saveIncoming([ category ]);
                    break;
            }
        },
        CATEGORY_EDITED (state, [ category, categoryName ]) {
            switch (categoryName) {
                case 'account':
                    state.accounts.forEach( (account) => {
                        if (account.id === category.id) {
                            account.balance = category.balance;
                            account.currency = category.currency;
                            account.isHidden = category.isHidden;
                            account.name = category.name;
                            account.target = category.target;
                            account.accType = category.accType;
                            account.import = category.import;
                            account.cardNumber = category.cardNumber;
                        }
                    });
                    idb.saveAccounts([ category ]);
                    break;
                case 'spend':
                    state.spending.forEach( (spending) => {
                        if (spending.id === category.id) {
                            spending.currency = category.currency;
                            spending.isHidden = category.isHidden;
                            spending.name = category.name;
                            spending.target = category.target;
                            spending.accType = category.accType;
                        }
                    });
                    idb.saveSpending([ category ]);
                    break;
                case 'income':
                    state.incoming.forEach( (incoming) => {
                        if (incoming.id === category.id) {
                            incoming.currency = category.currency;
                            incoming.isHidden = category.isHidden;
                            incoming.name = category.name;
                            incoming.target = category.target;
                        }
                    });
                    idb.saveIncoming([ category ]);
                    break;
            }
        },
        CATEGORY_DELETED (state, [ categoryName, categoryId ]) {
            switch (categoryName) {
                case 'account':
                    state.accounts = state.accounts.filter( account => account.id != categoryId );
                    idb.deleteAccount(categoryId);
                    break;
                case 'spend':
                    state.spending = state.spending.filter( spend => spend.id != categoryId );
                    idb.deleteSpend(categoryId);
                    break;
                case 'income':
                    state.incoming = state.incoming.filter( income => income.id != categoryId );
                    idb.deleteIncome(categoryId);
                    break;
            }
        },
        HIDDEN_CATEGORIES_SWITCH (state, categorie) {
            switch (categorie) {
                case 'accounts':
                    state.showHiddenAccounts = !state.showHiddenAccounts;
                    break;
                case 'spending':
                    state.showHiddenSpending = !state.showHiddenSpending;
                    break;
                case 'incoming':
                    state.showHiddenIncoming = !state.showHiddenIncoming;
                    break;
            }
        },
        OWNERS_FETCHED (state, [ id, type, creator, accOwners ]) {
            if (type === 'account') {
                state.accounts.forEach( account => {
                    if (account.id === id) {
                        account.accOwners = accOwners;
                        account.creator = creator;
                    }
                });
            } else {
                state.spending.forEach( spend => {
                    if (spend.id === id) {
                        spend.accOwners = accOwners;
                        spend.creator = creator;
                    }
                });
            }
        }
    }
}