import getDb from './getDb.js';

export default {
    async getOperations(dates) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['operations'],'readonly');
            trans.oncomplete = () => {
                resolve(operations);
            };

            let store = trans.objectStore('operations');
            let operations = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    if (dates) {
                        let operationDate = new Date(cursor.value.date).setHours(0,0,0,0);
                        if (operationDate >= new Date(dates[0]).setHours(0,0,0,0) && operationDate < dates[1])
                            operations.push(cursor.value);
                    } else {
                        operations.push(cursor.value);
                    }
                    cursor.continue();
                }
            };
        });
    },
    async saveOperations(operations) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['operations'],'readwrite');
            trans.oncomplete = () => {
                resolve(operation);
            };

            let store = trans.objectStore('operations');
            operations.forEach(operation => {
                store.put(operation);
            });
            let operation;
            if (operations.length === 1) {
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        operation = cursor.value;
                        cursor.continue();
                    }
                };
            }
        });
    },
    async deleteOperations(id) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['operations'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('operations');
            if (id) {
                store.delete(Number(id));
            } else {
                store.clear();
            }
        });
    }
}