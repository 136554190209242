import Vue from 'vue';
import Vuex from 'vuex';
import User from './userModule';
import Operations from './operationsModule';
import Categories from './categoriesModule';
import Debts from './debtsModule';
import BanksAPI from './banksAPIModule';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        dates: [ new Date(new Date().setDate(1)), new Date() ],
        serverTimezoneOffset: 3, // Russian server timeZone
        screenWidth: 1480
    },
    getters: {
        getDates(state) {
            return state.dates;
        },
        getServerTimezoneOffset(state) {
            return state.serverTimezoneOffset;
        },
        isMobileMenu(state) {
            return state.screenWidth < 769;
        }
    },
    actions: {},
    mutations: {
        DATE_CHANGED (state, dates) {
            state.dates = dates;
        },
        SCREEN_WIDTH_CHANGED (state, newWidth) {
            state.screenWidth = newWidth;
        }
    },
    modules: {
        User,
        Operations,
        Categories,
        Debts,
        BanksAPI
    }
});
