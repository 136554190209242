<template>
  <div class="d-flex justify-content-center">
    <half-circle-spinner
        :animation-duration="1000"
        :size="30"
        :color="'#3E2BCE'"
    />
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners';

export default {
    name: 'Spinner',
    components: {
        HalfCircleSpinner
    }
}
</script>

<style lang="scss"></style>
