<template>
    <div class="modal-wrap">
        <i class="fal fa-times" @click="$router.push('/')"></i>
        <div class="modal-container">
            <swiper :options="swiperOption" ref="mySwiper">
                <!-- slides -->
                <swiper-slide>
                    <img src="/img/screen/ios1.png" alt="ios1">
                    <p>1. Откройте сайт в Safari и нажмите "Импорт"</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="/img/screen/ios2.png" alt="ios2">
                    <p>2. В меню выберите: На экран "Домой"</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="/img/screen/ios3.png" alt="ios3">
                    <p>3. Нажмите "Добавить"</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="/img/screen/ios4.png" alt="ios4">
                    <p>4. На домашнем экране появится иконка</p>
                </swiper-slide>
                <!-- Optional controls -->
                <div class="swiper-pagination"  slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
    name: 'app',
    components: {
        swiper,
        swiperSlide
    },
    data: function () {
        return {
            swiperOption: {
                centeredSlides: true,
                centeredSlidesBounds: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar'
                },
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        }
    }
}
</script>


<style lang="scss">
    .modal-wrap {
        position: fixed;
        display: inline-flex;
        top: 0;
        min-height: 100vh;
        overflow: auto;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.8);
        z-index: 999999;
        &>i {
            position: absolute;
            top: 25px;
            right: 25px;
            font-size: 38px;
            color: #fff;
            cursor: pointer;
            z-index: 9999;
        }
        p {
            color: #fff;
            text-align: center;
            font-size: 18px;
        }
        .modal-container {
            display: inline-block;
            width: 100%;
            max-width: 380px;
            height: auto;
            .hide {
                display: none;
            }
            img {
                display: block;
                max-width: 310px;
                margin: 20px auto;
            }
            .swiper-button-next {
                color: '#fff';
                right: 0;
            }
            .swiper-button-prev {
                color: '#fff';
                left: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .modal-wrap {
            &>i {
                right: 15px;
                top: 15px;
                font-size: 25px;
            }
            p {
                font-size: 16px;
            }
            .modal-container {
                max-width: 360px;
                img {
                    max-width: 280px;
                }
            }
        }
    }
</style>
