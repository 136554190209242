import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store/store';
import mixins from './mixins/mixins';
import vuetify from './plugins/vuetify';
import { VueHammer } from 'vue2-hammer';
import './registerServiceWorker';

import Spinner from './components/gloabal/Spinner';

import './assets/main.scss';

Vue.config.productionTip = false;

// add auth token to all requests, if exist + timeout
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('auth');
    if (token) {
        config.headers['auth'] = token;
    }
    if (!config.timeout) {
        config.timeout = 6000;
    }
    return config;
});

// Base url for requests
axios.defaults.baseURL = (process.env.NODE_ENV === 'production') ? 'https://splitmyfunds.com/' : '/';

Vue.use(VueAxios, axios);
Vue.use(VueHammer);

Vue.component("spinner", Spinner);

Vue.mixin(mixins);

Vue.filter('amount', function (value) {
    if (store.getters.currentUser.settings?.isRound) {
        // round
        value = Math.floor(value);
    } else {
        // leave only 2 digits after coma
        value = Math.round(value * 100) / 100;
    }
    // add space every 3d digit 1000 -> 1 000
    return value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
