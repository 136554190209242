<template>
    <VueScrollbar
        :maxHeight="contentHeight"
    >
    <i @click="$emit('changeFormat')" v-if="getOperationsByDay.length" class="far fa-chart-bar graphicBtn"></i>
    <transition-group tag="div" class="operations" name="list-complete">
        <div v-for="operations in getOperationsByDay" :key="operations[0].date" class="list-complete-item">
            <p>{{ getDate(operations[0].date) }}</p>
            <ul>
                <transition-group name="list-complete">
                <li v-for="operation in operations" :key="operation.id" class="list-complete-item">
                    <span class="delOperation" @click="delOperationBtn(operation.id)">
                        <i title="Удалить" class="far fa-times"></i>
                    </span>
                    <span class="editOperation" @click="$emit('editOperationBtn', operation)">
                        <i title="Редактировать" class="far fa-pencil"></i>
                    </span>
                    <label>
                        <span>{{ operation.time.slice(0,5) }}</span>
                        <span v-if="operation.from !== 'waitSelection'" class="categoryName nowrap" :class="operation.from">
                            {{ getCategoryName(operation.from, operation.fid) }}
                            <i v-if="isHiddenCategory(operation.from, operation.fid)" title="Скрыто" class="far fa-eye-slash"></i>
                        </span>
                        <span v-else class="nowrap">
                            <v-select
                                @change="categorySelected($event, 'from', operation.id, operation.amount, operation.currency)"
                                :items="getFromItems"
                                :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                                label="- Откуда -"
                                solo
                                flat
                            >
                                <template v-slot:selection="{ item }" >
                                    <v-list-item-content>
                                        <v-list-item-title class="accent--text">{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <template v-slot:item="{ item }" >
                                    <v-list-item-icon class="my-auto mr-4">
                                        <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                                        <v-icon class="ma-auto success--text" v-if="item.value.includes('income')">fal fa-dollar-sign</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </span>
                        <h6></h6>
                    </label>
                    <label>
                        <i class="far fa-long-arrow-right"></i>
                        <span v-if="operation.to !== 'waitSelection'" class="categoryName nowrap" :class="operation.to">
                            {{ getCategoryName(operation.to, operation.tid) }}
                            <i v-if="isHiddenCategory(operation.to, operation.tid)" title="Скрыто" class="far fa-eye-slash"></i>
                        </span>
                        <span v-else class="nowrap">
                            <v-select
                                @change="categorySelected($event, 'to', operation.id, operation.amount, operation.currency)"
                                :items="getToItems"
                                :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                                label="- Куда -"
                                solo
                                flat
                            >
                                <template v-slot:selection="{ item }" >
                                    <v-list-item-content>
                                        <v-list-item-title class="accent--text">{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <template v-slot:item="{ item }" >
                                    <v-list-item-icon class="my-auto mr-4">
                                        <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                                        <v-icon class="ma-auto" v-if="item.value.includes('spend')">fal fa-shopping-cart</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </span>
                        <i class="fal fa-pen-alt"></i>
                    </label>
                    <label>
                        <span class="nowrap">{{ operation.comment || '...' }}</span>
                        <span v-if="!operation.amount2" :class="operation.amount > 0 ? 'income' : 'spend'">{{ operation.amount | amount }} {{ $getCurrencySign( operation.currency ) }}</span>
                        <span v-else class="exchange">
                            {{ operation.amount2 | amount }} {{ $getCurrencySign( operation.currency2 ) }}
                            <span>{{ -operation.amount | amount }} {{ $getCurrencySign( operation.currency ) }}</span>
                        </span>
                    </label>
                </li>
                </transition-group>
                <li v-if="operations.length > 1">
                    <span class="total--big">Итого</span>
                    <span class="totalBlock">
                        <span class="spend total"><i class="fal fa-arrow-circle-down"></i> {{ getTotal(operations, true) | amount }} {{ $getCurrencySign() }}</span>
                        <span class="income total"><i class="fal fa-arrow-circle-up"></i> +{{ getTotal(operations, false) | amount }} {{ $getCurrencySign() }}</span>
                    </span>
                </li>
            </ul>
        </div>
    </transition-group>
    </VueScrollbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueScrollbar from 'vue-scrollbar-live';
import { get } from 'lodash';

export default {
    name: 'finances',
    components: { VueScrollbar },
    data() {
        return {
            days: [
                'Воскресенье',
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота',
            ],
            contentHeight: (document.documentElement.clientWidth < 1440) ? 'auto' : 'calc(100vh - 265px)'
        }
    },
    computed: {
        ...mapGetters(["getOperationsByDay","getAccountsAll","getSpendingAll","getIncomingAll","currentUser"]),
        getFromItems() {
            return [
                ...this.arrayToOptions(this.getAccountsAll, 'account'),
                ...this.arrayToOptions(this.getIncomingAll, 'income')
            ];
        },
        getToItems() {
            return [
                ...this.arrayToOptions(this.getSpendingAll, 'spend'),
                ...this.arrayToOptions(this.getAccountsAll, 'account')
            ];
        }
    },
    methods: {
        ...mapActions(["delOperation", "loadCategories", "editOperation", "editCategory"]),

        getCategoryName(category, id, param = 'name') { // param - is a parammetr of category, that needed (default - name)
            let item = {};
            switch (category) {
                case 'account':
                    item = this.getAccountsAll.find((account) => account.id === Number(id));
                    break;
                case 'spend':
                    item = this.getSpendingAll.find((spend) => spend.id === Number(id));
                    break;
                case 'income':
                    item = this.getIncomingAll.find((income) => income.id === Number(id));
                    break;
                default:
                    item.name = 'не задано';
            }

            return get(item, param, '-недоступно-');
        },
        isHiddenCategory(category, id) {
            let categoryObj = {};
            switch (category) {
                case 'account':
                    categoryObj = this.getAccountsAll.find((account) => account.id === Number(id));
                    break;
                case 'spend':
                    categoryObj = this.getSpendingAll.find((spend) => spend.id === Number(id));
                    break;
                case 'income':
                    categoryObj = this.getIncomingAll.find((income) => income.id === Number(id));
                    break;
                default:
                    return false;
            }

            return get(categoryObj, 'isHidden', false);
        },
        getTotal(operations, isSpending) {
            let amounts = operations.map(operation => {
                if (operation.from === 'account' && operation.to === 'account') {
                    return 0;
                }
                return this.$convertToDefultCurrency(operation.currency, operation.amount); // mixin
            });

            amounts = (isSpending) ? amounts.filter((amount) => amount < 0) : amounts.filter((amount) => amount > 0);

            return (amounts.length) ? amounts.reduce(( sum, amount ) => sum + amount).toFixed(2) : 0;
        },
        getDate(date) {
            // add three hours becouse of MSK tmeZoneOffset (on server)
            date = new Date(new Date(date).getTime() + 3000 * 60 * 60);

            return this.days[date.getDay()] + ', ' + this.addZero(date.getDate()) + '.' + this.addZero(date.getMonth() + 1);
        },
        addZero(date) {
            return date < 10 ? '0' + date : date;
        },
        delOperationBtn(id) {
            this.$showConfirmDialog({
                title: 'Безвозвратно удалить операцию?',
                description: 'Информация по категории будет пересчитана, но баланс счета останется тот же.',
                approvedCallback: async () => {
                    try {
                        await this.delOperation(id);
                        await this.loadCategories();
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        },
        async categorySelected(value, where, id, operationAmount, operationCurrency) {
            let categortyType = value.split('/')[0];
            let categoryId = value.split('/')[1];
            let amount2 = null;
            let currency2 = null;

            if (categortyType === 'account') {
                if (where === 'to') {
                    currency2 = this.getCategoryName(categortyType, categoryId, 'currency');
                    amount2 = -this.$convertToDefultCurrency(operationCurrency, operationAmount, currency2).toFixed(2);
                } else {
                    currency2 = operationCurrency;
                    amount2 = operationAmount;
                    operationCurrency = this.getCategoryName(categortyType, categoryId, 'currency');
                    operationAmount = this.$convertToDefultCurrency(currency2, amount2, operationCurrency).toFixed(2);
                }

                this.$showConfirmDialog({
                    title: (where === 'to') ?
                        `Добавить ${ amount2 } ${ this.$getCurrencySign(currency2) } на "${ this.getCategoryName(categortyType, categoryId) }" ?` :
                        `Списать ${ operationAmount } ${ this.$getCurrencySign(operationCurrency) } с "${ this.getCategoryName(categortyType, categoryId) }" ?`,
                    description: 'Баланс счета будет отредактирован.',
                    approvedCallback: async () => {
                        try {
                            await this.editCategory({
                                categoryName: categortyType,
                                categoryId,
                                balance: (where === 'to') ? amount2 : -operationAmount,
                                calculateBalance: true // indicates that balance should calculate with new balance instead be overridden
                            });
                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
            }

            try {
                if (where === 'to') {
                    await this.editOperation({ id, params: { to: categortyType, tid: categoryId, amount2, currency2 }});
                } else {
                    await this.editOperation({
                        id,
                        params: {
                            from: categortyType,
                            fid: categoryId,
                            amount: operationAmount,
                            currency: operationCurrency,
                            amount2,
                            currency2
                        }
                    });
                }
                await this.loadCategories();
            } catch (error) {
                console.log(error);
            }
        },
        arrayToOptions(categories, categoryName) {
            return categories.map(category => {
                return {
                    text: category.name,
                    value: '' + categoryName + '/' + category.id + '/' + category.currency + ''
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';
    @import '@/assets/list.scss';

    span select {
        height: 20px;
        text-align-last: center;
        outline: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
    }

</style>
<style lang="scss">
    .operations {
        .v-text-field.v-text-field--solo .v-input__control {
            height: 20px;
            min-height: 20px;
        }
        .v-text-field input {
            padding: 0;
        }
    }

    .scrollbar-wrap::after {
        position: absolute;
        bottom: 0;
        content: '';
        display: block;
        width: 100%;
        height: 28px;
        background: linear-gradient(rgba(255,255,255,0) 1%, #F3F7F9 100%);
    }
    .scrollbar {
        z-index: 1;
    }
</style>

