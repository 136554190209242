<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        :origin="(isMobileMenu) ? 'bottom right' : 'top right'"
        :top="isMobileMenu"
        offset-y
        min-width="320px"
        :nudge-top="(isMobileMenu) ? 15 : 0"
        @update:return-value="dateInitial"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="computedDateFormatted"
                readonly
                v-on="on"
                class="calendar"
                :class="{ 'calendar--framed': framed }"
            >
                <template v-slot:append>
                    <div v-on="on" class="v-input__icon v-input__icon--append">
                        <i class="far fa-calendar-alt"></i>
                        <div v-if="isMobileMenu">Период</div>
                    </div>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            no-title
            range
            locale="ru"
            :first-day-of-week="1"
        >
            <div class="calendar-shortcats">
                <v-chip
                    v-for="(shortcut, i) in shortcuts" :key="i"
                    @click="shortcut.onClick"
                >
                    {{ shortcut.text }}
                </v-chip>
            </div>
            <span class="cancel" @click="dateInitial">Отмена</span>
            <v-spacer></v-spacer>
            <span class="approved" @click="dateChanged()">ОК</span>
        </v-date-picker>
    </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'calendar',
    props: {
        framed: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters(["getDates", "isMobileMenu"]),
        computedDateFormatted () {
            return this.formatDate(this.dates[0]) + ' - ' + this.formatDate(this.dates[1])
        },
    },
    created() {
        this.initialDates = this.dates = [
            new Date(this.getDates[0] - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            new Date(this.getDates[1] - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        ]
    },
    data() {
        return {
            initialDates: [],
            dates: [],
            menu: false,
            shortcuts: [
                {
                    text: '7 дней',
                    onClick: () => {
                        let pastWeek = new Date();
                        pastWeek.setDate(pastWeek.getDate() - 6);
                        this.dateChanged([ pastWeek, new Date() ]);
                    }
                },
                {
                    text: 'Этот месяц',
                    onClick: () => {
                        let month = new Date();
                        month.setDate(1);
                        this.dateChanged([ month, new Date() ]);
                    }
                },
                {
                    text: 'Прошлый месяц',
                    onClick: () => {
                        let today = new Date();
                        let startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 12, 0, 0);
                        let endDate = new Date(today.getFullYear(), today.getMonth(), 0, 12, 0, 0);
                        this.dateChanged([ startDate, endDate ]);
                    }
                }
            ]
        }
    },
    methods: {
        ...mapActions(["loadOperations","loadCategories"]),
        ...mapMutations(["DATE_CHANGED"]),
        dateInitial() {
            this.dates = this.initialDates;
            this.menu = false;
        },
        formatDate (date) {
            if (!date) return ""

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        async dateChanged(shortcutDates) {
            let date1 = (shortcutDates) ? shortcutDates[0] : new Date(this.dates[0]);
            let date2 = (shortcutDates) ? shortcutDates[1] : new Date(this.dates[1]);
            let dates = [date1, date2];

            if (shortcutDates) {
                this.dates = [
                    new Date(date1 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
                    new Date(date2 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
                ];
            }

            if (date1 > date2) {
                this.dates = [this.dates[1], this.dates[0]]
                dates = [date2, date1]
            }

            this.initialDates = this.dates;

            this.DATE_CHANGED(dates);
            try {
                await this.loadOperations(dates);
                await this.loadCategories();
            } catch (err) {
                console.log(err);
            }

            this.menu = false;
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/vars.scss';

    .v-application {
        .calendar-shortcats {
            text-align: center;
        }
        .v-menu__content {
            margin-top: 2px;
        }
        .v-input.calendar {
            flex: 0 0 auto;
            min-width: 230px;
            padding-top: 0;
            margin-top: 0;
            font-size: 18px;

            &--framed {
                background: #fff;
                border: $border;
                padding: 0 20px;
                box-sizing: content-box;
                border-radius: $border-radius;
            }

            input {
                max-height: 46px;
                height: 46px;
                line-height: 47px;
                color: $text;
                text-align: right;
                cursor: pointer;
            }

            .v-input__slot:before {
                display: none;
            }

            .v-input__append-inner {
                margin-top: 8px;
                padding-left: 8px;
                cursor: pointer;

                i {
                    color: $text;
                    font-size: 20px;
                }
            }

            &--inline {
                min-width: unset;
                width: 115px;

                input {
                    text-align: center;
                }
            }
            &--inline-time {
                min-width: unset;
                width: 70px;
                margin-left: -15px;

                input {
                    text-align: center;
                }
            }
        }
        .v-date-picker-table {
            height: 220px;
        }
        .v-picker__actions {
            max-width: 320px;
            flex-wrap: wrap;

            .calendar-shortcats {
                flex: 1 0 100%;
                max-width: 100%;
                margin-bottom: 16px;

                .v-chip {
                    padding: 2px 10px;
                    background-color: rgba(32, 80, 159, 0.8);
                    color: #fff;
                    margin-left: 4px;
                    font-size: 12px;
                    cursor: pointer;

                    &.v-size--default {
                        height: unset;
                    }
                }
            }
            &>span {
                text-transform: capitalize;
                padding: 5px 15px;
                margin-left: 5px;
                cursor: pointer;

                &.cancel {
                    color: $text-regular;
                }
                &.approved {
                    color: $text;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .v-application {
            .v-input.calendar {
                font-size: 16px;

                input {
                    max-height: 38px;
                    height: 38px;
                }
                .v-input__append-inner {
                    margin-top: 4px;

                    i {
                        font-size: 18px;
                    }
                }

                &--inline {
                    min-width: unset;
                    width: 110px;

                    input {
                        text-align: center;
                        max-height: 46px;
                        height: 46px;
                    }
                }
                &--inline-time {
                    min-width: unset;
                    width: 65px;
                    margin-left: -20px;

                    input {
                        text-align: center;
                        max-height: 46px;
                        height: 46px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .v-application {
            .v-menu__content {
                margin-bottom: 20px;
            }
            .v-input.calendar {
                min-width: unset;

                .v-input__icon {
                    height: auto;
                    flex-wrap: wrap;
                    font-size: 14px;
                    color: $text-regular;
                    width: 45px;
                }

                .v-input__append-inner {
                    margin: auto;
                    padding-left: 0;
                    height: auto;

                    &:active {
                        .v-input__icon,
                        i {
                            color: $text;
                        }
                    }
                    i {
                        color: $text-regular;
                        font-size: 20px;
                        margin-bottom: 5px;
                    }
                }

                &.v-text-field:not(.calendar--inline) > .v-input__control > .v-input__slot > .v-text-field__slot {
                    display: none;
                }
                &.v-text-field.calendar--inline-time > .v-input__control > .v-input__slot > .v-text-field__slot {
                    display: flex;
                }

                &--inline {
                    min-width: unset;
                    width: 110px;

                    input {
                        text-align: center;
                    }
                }
                &--inline-time {
                    min-width: unset;
                    width: 60px;
                    margin-left: -10px;

                    input {
                        text-align: center;
                    }
                }
            }
        }
    }
</style>
