import getDb from './getDb.js';

export default {
    async getUser() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['user'], 'readonly');
            trans.oncomplete = () => {
                resolve(user);
            };

            let store = trans.objectStore('user');
            let user = {};

            store.openCursor().onsuccess = e => {
                user = e.target.result.value;
            };
        });
    },
    async saveUser(user) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['user'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('user');
            store.put(user);
        });
    },
    async deleteUser(user) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['user'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('user');
            store.delete(user.email);
        });
    },
    async saveRates(rates) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['exchangeRates'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('exchangeRates');
            store.put(rates);
        });
    },
    async getRates() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['exchangeRates'], 'readonly');
            trans.oncomplete = () => {
                resolve(rates.exchangeRates);
            };

            let store = trans.objectStore('exchangeRates');
            let rates = {};

            store.openCursor().onsuccess = e => {
                rates = e.target.result.value;
            };
        });
    }
}