<template>
  <div class="dashWrapper">
    <div class="dashMenu">
        <nav>
            <div>
                <router-link to="/" class="dashLink">Счета</router-link>
                <a @click.prevent="closeDash('finances')" href="/finances">Операции</a>
                <a @click.prevent="closeDash('debts')" href="/debts">Долги</a>
            </div>
            <Calendar/>
        </nav>
    </div>

    <div class="dashItem accounts" :class="openedSizeClass(accountsOpened, getAccounts.length)">
        <div class="dashTitle">
            <p>Счета</p>
            <div class="details">
                <span
                    v-if="isHiddenAccounts"
                    @click="showHiddenAccountsClicked"
                    :title="(showHiddenAccounts) ? 'Спрятать скрытые счета' : 'Показать скрытые счета'"
                    class="hiddenCategories"
                >
                    <i class="far" :class="(showHiddenAccounts) ? 'fa-eye-slash': 'fa-eye'"></i> скрытые
                </span>
                <span>всего</span>
                <p>{{ total(getAccounts) | amount }} {{ $getCurrencySign() }}</p>
            </div>
        </div>
        <div class="category" v-for="account in getAccounts" :key="account.id">
            <p>{{ account.name }}</p>
            <span @click="openSettings('account', account.id)" :class="[account.accType, account.import]">
                <i v-if="account.isHidden" class="far fa-eye-slash hiddenCategory"></i>
                <i v-if="!account.target" class="fal fa-university"></i>
                <i v-else class="percent">{{ (account.balance/account.target*100).toFixed() + '%' }}</i>
            </span>
            <p class="amount">{{ account.balance | amount }} {{ $getCurrencySign(account.currency) }}</p>
            <h6 v-if="account.target">из {{ account.target | amount }} {{ $getCurrencySign(account.currency) }}</h6>
        </div>
        <div class="category default">
            <span @click="openSettings('account')">
                <i class="fal fa-plus"></i>
            </span>
        </div>
        <p v-if="getAccounts.length > 3 && !isDashOpened" @click="accountsOpened = !accountsOpened">
            <span v-if="!accountsOpened">Развернуть <i class="far fa-chevron-down"></i></span>
            <span v-else><i class="far fa-chevron-up"></i></span>
        </p>
    </div>
    <div class="dashItem spending" :class="openedSizeClass(spendingOpened, getSpending.length)">
        <div class="dashTitle" title="(за выбранный приод)">
            <p>Расходы</p>
            <div class="details">
                <span
                    v-if="isHiddenSpending"
                    @click="HIDDEN_CATEGORIES_SWITCH('spending'); spendingOpened = (showHiddenSpending) ? true : false;"
                    :title="(showHiddenSpending) ? 'Спрятать скрытые категории' : 'Показать скрытые категории'"
                    class="hiddenCategories"
                >
                    <i class="far" :class="(spendingOpened) ? 'fa-eye-slash': 'fa-eye'"></i> скрытые
                </span>
                <span>всего</span>
                <p>{{ total(getSpending) | amount }} {{ $getCurrencySign() }}</p>
            </div>
        </div>
        <div class="category" v-for="spend in getSpending" :class="(spend.target && spend.balance > spend.target) ? 'over' : ''" :key="spend.id">
            <p>{{ spend.name }}</p>
            <span @click="openSettings('spend', spend.id)" :class="spend.accType">
                <i v-if="spend.isHidden" class="far fa-eye-slash hiddenCategory"></i>
                <i v-if="!spend.target" :class="(spend.target) ? 'icon' : ''" class="fal fa-shopping-cart"></i>
                <i v-else class="percent">{{ (spend.balance/spend.target*100).toFixed() + '%' }}</i>
            </span>
            <p class="amount">{{ spend.balance | amount }} {{ $getCurrencySign(spend.currency) }}</p>
            <h6 v-if="spend.target">из {{ spend.target | amount }} {{ $getCurrencySign(spend.currency) }}</h6>
        </div>
        <div class="category default">
            <span @click="openSettings('spend')">
                <i class="fal fa-plus"></i>
            </span>
        </div>
        <p v-if="getSpending.length > 3 && !isDashOpened" @click="spendingOpened = !spendingOpened">
            <span v-if="!spendingOpened">Развернуть <i class="far fa-chevron-down"></i></span>
            <span v-else><i class="far fa-chevron-up"></i></span>
        </p>
    </div>
    <div class="dashItem incoming" :class="openedSizeClass(incomingOpened, getIncoming.length)">
        <div class="dashTitle" title="(за выбранный приод)">
            <p>Доходы</p>
            <div class="details">
                <span
                    v-if="isHiddenIncoming"
                    @click="HIDDEN_CATEGORIES_SWITCH('incoming'); incomingOpened = (showHiddenIncoming) ? true : false;"
                    :title="(showHiddenIncoming) ? 'Спрятать скрытые категории' : 'Показать скрытые категории'"
                    class="hiddenCategories"
                >
                    <i class="far" :class="(incomingOpened) ? 'fa-eye-slash': 'fa-eye'"></i> скрытые
                </span>
                <span>всего</span>
                <p>{{ total(getIncoming) | amount }} {{ $getCurrencySign() }}</p>
            </div>
        </div>
        <div class="category" v-for="income in getIncoming" :key="income.id">
            <p>{{ income.name }}</p>
            <span @click="openSettings('income', income.id)">
                <i v-if="income.isHidden" class="far fa-eye-slash hiddenCategory"></i>
                <i v-if="!income.target" :class="(income.target) ? 'icon' : ''" class="fal fa-dollar-sign"></i>
                <i v-else class="percent">{{ (income.balance/income.target*100).toFixed() + '%' }}</i>
            </span>
            <p class="amount">{{ income.balance | amount }} {{ $getCurrencySign(income.currency) }}</p>
            <h6 v-if="income.target">из {{ income.target | amount }} {{ $getCurrencySign(income.currency) }}</h6>
        </div>
        <div class="category default">
            <span @click="openSettings('income')">
                <i class="fal fa-plus"></i>
            </span>
        </div>
        <p v-if="getIncoming.length > 3 && !isDashOpened" @click="incomingOpened = !incomingOpened">
            <span v-if="!incomingOpened">Развернуть <i class="far fa-chevron-down"></i></span>
            <span v-else><i class="far fa-chevron-up"></i></span>
        </p>
    </div>
    <div class="dashItem">
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Calendar from '@/components/Calendar';
import EventBus from '@/event-bus';
import { get } from 'lodash';

export default {
    name: 'Dashboard',
    components: { Calendar },
    async mounted() {
        EventBus.$on('UPDATE_BANKS_OPERATIONS', this.updateBanksOperations);

        try {
            if (!this.getOperations.length) await this.loadOperations();
            await this.loadCategories();
            this.checkCategories();
            await this.updateBanksOperations();
        } catch (err) {
            console.log(err);
        }
    },
    data: function () {
        return {
            accountsOpened: false,
            spendingOpened: false,
            incomingOpened: false,
        }
    },
    computed: {
        ...mapGetters([
            "getOperations",
            "getAccounts",
            "isHiddenAccounts",
            "showHiddenAccounts",
            "getSpending",
            "isHiddenSpending",
            "showHiddenSpending",
            "getIncoming",
            "isHiddenIncoming",
            "showHiddenIncoming",
            "currentUser"
        ]),
        isDashOpened() {
            return this.currentUser.settings?.isDashOpened;
        }
    },
    methods: {
        ...mapActions([
            "loadOperations",
            "loadCategories",
            "addOperation",
            "checkYandexOperations",
            "checkYandexBalance",
            "checkMonobankOperations",
            "checkMonobankBalance",
        ]),
        ...mapMutations(["HIDDEN_CATEGORIES_SWITCH"]),
        async showHiddenAccountsClicked() {
            this.HIDDEN_CATEGORIES_SWITCH('accounts');
            this.accountsOpened = (this.showHiddenAccounts) ? true : false;
            try {
                await this.updateBanksOperations();
            } catch (error) {
                console.log(error);
            }
        },
        openSettings(categoryName, id) {
            if (id) {
                this.$router.push({ path: '/category/' + categoryName + '/' + id + '/' });
            } else {
                this.$router.push({ path: '/add/' + categoryName });
            }
        },
        checkCategories() {
            if (!this.getAccounts.length) {
                this.$router.push({ path: '/add/account' });
            } else if (!this.getSpending.length) {
                this.$router.push({ path: '/add/spend' });
            } else if (!this.getIncoming.length) {
                this.$router.push({ path: '/add/income' });
            }
        },
        total(categories) {
            if (categories.length) {
                return categories.map( account => {
                    return this.$convertToDefultCurrency(account.currency, account.balance); // mixin
                }).reduce(( sum, amount ) => {
                    return sum + amount;
                }).toFixed(2);
            } else {
                return 0;
            }
        },
        closeDash(linkTo) {
            this.$emit('closeDashClicked');
            (linkTo === 'finances') ? this.$router.push('/') : this.$router.push('/debts');
        },
        openedSizeClass(isOpened, categoriesCount) {
            if (isOpened || this.isDashOpened) {
                if (categoriesCount > 7 && categoriesCount < 12) {
                    return 'opened max490';
                } else if (categoriesCount > 11 && categoriesCount < 16) {
                    return 'opened max640';
                } else if (categoriesCount > 15) {
                    return 'opened maxnone';
                } else {
                    return 'opened';
                }
            } else {
                return '';
            }
        },
        async updateBanksOperations() {
            for (let account of this.getAccounts) {
                // if User has yandex authorization && yandex account - load new operations
                if (account.import === 'yandex' && this.currentUser.yandex) {
                    let newOperations = await this.checkYandexOperations();
                    for (let operation of newOperations) {
                        await this.addYandexOpearation(operation, account.id);
                    }
                    if (get(newOperations, 'length')) {
                        await this.checkYandexBalance(account);
                    }
                } else if (account.import === 'monobank' && this.currentUser.monobank) {
                    let newOperations = await this.checkMonobankOperations(account.cardNumber);
                    for (let operation of newOperations) {
                        /*
                            Monobank return's operation amount correctly in account currency,
                            but currencyCode is operation credit code, for example: I paid 0.3 cent from dollar card in subway (8 hrn credit),
                            but mono return 0.3 hrn, thats why we should check, is currency the same with account currency.
                        */
                        if (operation._currencyCode.code !== account.currency) operation._currencyCode.code = account.currency;

                        await this.addMonobankOpearation(operation, account.id);
                    }
                    if (get(newOperations, 'length')) {
                        await this.checkMonobankBalance(account);
                        await this.loadOperations();
                    }
                }
            }
        },
        async addYandexOpearation(operation, yandexAccountId) {
            let from, fid, to, tid;
            if (operation.direction === 'in') {
                from = 'waitSelection';
                fid = 0;
                to = 'account';
                tid = yandexAccountId;
            } else {
                from = 'account';
                fid = yandexAccountId;
                to = 'waitSelection';
                tid = 0;
            }

            try {
                await this.addOperation({
                    date: new Date(new Date(operation.datetime) - new Date().getTimezoneOffset() * 60000),
                    time: new Date(operation.datetime).toLocaleTimeString('en-GB'),
                    from,
                    fid,
                    to,
                    tid,
                    amount: (operation.direction === 'in') ? operation.amount : -operation.amount,
                    currency: 'RUB',
                    comment: operation.title,
                    imported: 'yandex',
                    importId: operation.operation_id
                });
            } catch (error) {
                console.log(error);
            }
        },
        async addMonobankOpearation(operation, monobankAccountId) {
            let from, fid, to, tid;
            if (operation._amount >= 0) {
                from = 'waitSelection';
                fid = 0;
                to = 'account';
                tid = monobankAccountId;
            } else {
                from = 'account';
                fid = monobankAccountId;
                to = 'waitSelection';
                tid = 0;
            }

            try {
                await this.addOperation({
                    date: new Date(new Date(operation._time) - new Date().getTimezoneOffset() * 60000),
                    time: new Date(operation._time).toLocaleTimeString('en-GB'),
                    from,
                    fid,
                    to,
                    tid,
                    amount: operation._amount / Math.pow(10, operation._currencyCode.digits),
                    currency: operation._currencyCode.code,
                    comment: operation._description,
                    imported: 'monobank',
                    importId: operation._id
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
    watch: {
        $route(to) {
            this.$emit('closeDashClicked');
            if (to.fullPath === "/") this.checkCategories();
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';
    @import '@/assets/home.scss';

</style>

<style lang="scss">
    @import '@/assets/vars.scss';

    .dashMenu {
        display: none;
        flex: 1 0 100%;
        max-width: 100%;
        a, label {
            &:hover, &.router-link-exact-active {
                border-bottom: none;
            }
            &.dashLink {
                border-bottom: 2px solid $main;
            }
        }
    }

    .dashItem {
        position: relative;
        flex: 0 0 580px;
        max-width: 580px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        flex-wrap: wrap;
        background: #fff;
        border-radius: 8px;
        box-shadow: $shadow-big;
        margin-bottom: 45px;
        max-height: 234px;
        transition: max-height 0.3s linear;
        overflow: hidden;
        &>p {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            text-align: center;
            height: 25px;
            cursor: pointer;
            span {
                padding: 8px 16px;
                border-radius: 8px 8px 0 0;
                background: rgba(255, 255, 255);
                border: 1px solid #EBEBEB;
                opacity: 0.8;
                transition: all 0.3s ease;
            }
            i {
                font-size: 14px;
                margin-left: 2px;
            }
            &:hover span {
                color: $text;
                opacity: 1;
            }
        }
        &.opened {
            max-height: 345px;
            &.max490 {
                max-height: 490px;
            }
            &.max640 {
                max-height: 640px;
            }
            &.maxnone {
                max-height: none;
            }
        }
        &.incoming {
            margin-bottom: 35px;
        }
        &:last-child {
            margin-bottom: 0;
            visibility: hidden;
        }
        .dashTitle {
            flex: 1 0 100%;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: $border;
            padding: 12px 15px;
            p {
                font-size: 20px;
                display: inline-block;
                margin-bottom: 0;
                line-height: 1;
                text-shadow: $shadow;
            }
            &>p {
                color: $text;
            }
            .details span {
                margin-right: 10px;
                &.hiddenCategories {
                    cursor: pointer;
                    border-right: 1px solid $text-regular;
                    padding-right: 8px;
                    i {
                        position: relative;
                        bottom: -1px;
                        font-size: 12px;
                        margin-right: 2px;
                        margin-left: 5px;
                    }
                }
            }
        }
        .category {
            position: relative;
            flex: 0 0 25%;
            max-width: 25%;
            margin: 20px 0 20px 0;
            padding: 0 10px 0 10px;
            text-align: center;
            span {
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                color: #fff;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                cursor: pointer;
                margin: 8px 0;
                i.hiddenCategory {
                    position: absolute;
                    font-size: 16px;
                    color: $text-regular;
                    top: 0;
                    left: 1px;
                }
                &.multi {
                    border: 2px solid $main !important;
                }
            }
            i.percent {
                display: inline-block;
                font-size: 18px;
            }
            &:hover h6 {
                opacity: 1;
            }
            &.default {
                span {
                    color: $border-color;
                    background-color: transparent;
                    border: $border2;
                    margin: 0;
                }
            }
            &.over {
                p.amount, h6 {
                    color: $error;
                }
                span {
                    border: 1px solid $error;
                }
            }
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                line-height: 1;
                color: $text;
            }
            h6 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: absolute;
                bottom: -18px;
                left: 0;
                padding: 0 10px 0 10px;
                width: 100%;
                font-size: 14px;
                margin: 0;
                line-height: 1;
                color: $text-regular;
                opacity: 0;
            }
        }
        &.accounts {
            .details p {
                color: $orange;
            }
            .category:not(.default) {
                span {
                    background-color: $orange;
                }
            }
        }
        &.spending {
            .category:not(.default) {
                span {
                    background-color: $red;
                }
            }
        }
        &.incoming {
            .details p {
                color: $second;
            }
            .category:not(.default) {
                span {
                    background-color: $second;
                }
            }
        }
    }

    @media screen and (max-width: 1750px) {
        .dashItem {
            flex: 0 0 480px;
            max-width: 480px;
            margin-bottom: 25px;
            &.incoming {
                margin-bottom: 25px;
            }
        }
    }

    @media screen and (max-width: 1400px) {
        .dashMenu {
            display: block;
        }
        .dashItem {
            margin-right: 15px;
            margin-left: 15px;
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 1024px) {
        .dashItem {
            margin-bottom: 40px;
            max-height: 220px;
            .dashTitle {
                p {
                    font-size: 16px;
                }
            }
            .category {
                margin: 15px 0 25px 0;
                span {
                    font-size: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .dashMenu {
            display: none;
        }
        .dashWrapper {
            display: flex;
            justify-content: center;
            max-width: 100%;
            flex-wrap: wrap;
        }
        .dashItem {
            margin-bottom: 25px;
        }
    }

    @media screen and (max-width: 610px) {
        .dashItem {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: space-around;
            align-items: center;
            border-radius: 0;
            border: $border;
            box-shadow: none;
            margin: 0 0 25px 0;
            max-height: none;
            transition: height 0.3s ease-out;
            overflow: hidden;
            &.opened {
                max-height: none;
                &.max490,
                &.max640 {
                    max-height: none;
                }
            }
            .category {
                flex: 1 0 auto;
                max-width: auto;
                min-width: 124px;
            }
            &>p {
                display: none;
            }
        }
    }
</style>
