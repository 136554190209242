var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueScrollbar',{attrs:{"maxHeight":_vm.contentHeight}},[(_vm.getOperationsByDay.length)?_c('i',{staticClass:"far fa-chart-bar graphicBtn",on:{"click":function($event){return _vm.$emit('changeFormat')}}}):_vm._e(),_c('transition-group',{staticClass:"operations",attrs:{"tag":"div","name":"list-complete"}},_vm._l((_vm.getOperationsByDay),function(operations){return _c('div',{key:operations[0].date,staticClass:"list-complete-item"},[_c('p',[_vm._v(_vm._s(_vm.getDate(operations[0].date)))]),_c('ul',[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((operations),function(operation){return _c('li',{key:operation.id,staticClass:"list-complete-item"},[_c('span',{staticClass:"delOperation",on:{"click":function($event){return _vm.delOperationBtn(operation.id)}}},[_c('i',{staticClass:"far fa-times",attrs:{"title":"Удалить"}})]),_c('span',{staticClass:"editOperation",on:{"click":function($event){return _vm.$emit('editOperationBtn', operation)}}},[_c('i',{staticClass:"far fa-pencil",attrs:{"title":"Редактировать"}})]),_c('label',[_c('span',[_vm._v(_vm._s(operation.time.slice(0,5)))]),(operation.from !== 'waitSelection')?_c('span',{staticClass:"categoryName nowrap",class:operation.from},[_vm._v(" "+_vm._s(_vm.getCategoryName(operation.from, operation.fid))+" "),(_vm.isHiddenCategory(operation.from, operation.fid))?_c('i',{staticClass:"far fa-eye-slash",attrs:{"title":"Скрыто"}}):_vm._e()]):_c('span',{staticClass:"nowrap"},[_c('v-select',{attrs:{"items":_vm.getFromItems,"menu-props":{ offsetY: true, maxHeight: '50vh' },"label":"- Откуда -","solo":"","flat":""},on:{"change":function($event){return _vm.categorySelected($event, 'from', operation.id, operation.amount, operation.currency)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"accent--text"},[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',{staticClass:"my-auto mr-4"},[(item.value.includes('account'))?_c('v-icon',{staticClass:"ma-auto orange--text"},[_vm._v("fal fa-university")]):_vm._e(),(item.value.includes('income'))?_c('v-icon',{staticClass:"ma-auto success--text"},[_vm._v("fal fa-dollar-sign")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})],1),_c('h6')]),_c('label',[_c('i',{staticClass:"far fa-long-arrow-right"}),(operation.to !== 'waitSelection')?_c('span',{staticClass:"categoryName nowrap",class:operation.to},[_vm._v(" "+_vm._s(_vm.getCategoryName(operation.to, operation.tid))+" "),(_vm.isHiddenCategory(operation.to, operation.tid))?_c('i',{staticClass:"far fa-eye-slash",attrs:{"title":"Скрыто"}}):_vm._e()]):_c('span',{staticClass:"nowrap"},[_c('v-select',{attrs:{"items":_vm.getToItems,"menu-props":{ offsetY: true, maxHeight: '50vh' },"label":"- Куда -","solo":"","flat":""},on:{"change":function($event){return _vm.categorySelected($event, 'to', operation.id, operation.amount, operation.currency)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"accent--text"},[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',{staticClass:"my-auto mr-4"},[(item.value.includes('account'))?_c('v-icon',{staticClass:"ma-auto orange--text"},[_vm._v("fal fa-university")]):_vm._e(),(item.value.includes('spend'))?_c('v-icon',{staticClass:"ma-auto"},[_vm._v("fal fa-shopping-cart")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})],1),_c('i',{staticClass:"fal fa-pen-alt"})]),_c('label',[_c('span',{staticClass:"nowrap"},[_vm._v(_vm._s(operation.comment || '...'))]),(!operation.amount2)?_c('span',{class:operation.amount > 0 ? 'income' : 'spend'},[_vm._v(_vm._s(_vm._f("amount")(operation.amount))+" "+_vm._s(_vm.$getCurrencySign( operation.currency )))]):_c('span',{staticClass:"exchange"},[_vm._v(" "+_vm._s(_vm._f("amount")(operation.amount2))+" "+_vm._s(_vm.$getCurrencySign( operation.currency2 ))+" "),_c('span',[_vm._v(_vm._s(_vm._f("amount")(-operation.amount))+" "+_vm._s(_vm.$getCurrencySign( operation.currency )))])])])])}),0),(operations.length > 1)?_c('li',[_c('span',{staticClass:"total--big"},[_vm._v("Итого")]),_c('span',{staticClass:"totalBlock"},[_c('span',{staticClass:"spend total"},[_c('i',{staticClass:"fal fa-arrow-circle-down"}),_vm._v(" "+_vm._s(_vm._f("amount")(_vm.getTotal(operations, true)))+" "+_vm._s(_vm.$getCurrencySign()))]),_c('span',{staticClass:"income total"},[_c('i',{staticClass:"fal fa-arrow-circle-up"}),_vm._v(" +"+_vm._s(_vm._f("amount")(_vm.getTotal(operations, false)))+" "+_vm._s(_vm.$getCurrencySign()))])])]):_vm._e()],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }