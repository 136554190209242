<template>
    <header :class="{ 'absolute' : !isAuthenticated, 'opened' : isMenuOpened }">
        <router-link class="logo" :class="{ 'logo--landing': !isAuthenticated, 'logo--short': isOldUser }" to="/">
            <img src="/img/logo.svg" alt="logo"> SplitMy<span>Funds</span>
        </router-link>
        <span v-if="!isAuthenticated" class="menu-btn" @click="isMenuOpened = !isMenuOpened">
            <i v-if="!isMenuOpened" class="fal fa-bars"></i>
            <i v-else class="fal fa-times"></i>
        </span>

        <nav v-if="isAuthenticated" :class="{ 'opened' : isMenuOpened }">
            <router-link to="/">Учет</router-link>
            <router-link v-if="isOldUser" to="/statistics">Статистика</router-link>
            <router-link to="/profile">Профиль</router-link>
            <router-link to="/contacts">Контакты</router-link>
        </nav>
        <nav v-else :class="{ 'opened' : isMenuOpened }">
            <a @click="closeMenu" href="/#about">Зачем это нужно?</a>
            <a @click="closeMenu" href="/#features">Функционал</a>
            <a @click="closeMenu" href="/#process">С чего начать?</a>
            <a @click="closeMenu" href="/#footer">Контакты</a>
        </nav>

        <div v-if="isAuthenticated" class="controls">
            <span class="rates" v-if="getExchangeRates.UAH" title="покупка / продажа">
                {{ getReadableRates }} <i></i>
            </span>
            <span class="controls__btn" v-if="isOldUser" @click="$router.push('/statistics')">
                <i class="fal fa-chart-bar"></i>
            </span>
            <span class="controls__btn" @click="$router.push('/contacts')">
                <i class="fal fa-envelope"></i>
            </span>
            <span @click="$router.push('/profile')">
                <i class="fal fa-user-circle"></i> <span class="controls__text">{{ currentUser.email }}</span>
            </span>
            <span @click="disconnect" class="controls__logout">
                <i class="fal fa-sign-out"></i> <span class="controls__text">Выйти</span>
            </span>
        </div>
        <div v-else class="controls">
            <span @click="$router.push('/registration')"><i class="fal fa-user-circle"></i> Регистрация</span>
            <span @click="$router.push('/login')"><i class="fal fa-sign-in"></i> Вход</span>
        </div>

    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Header',
    data() {
        return {
            isMenuOpened: false,
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', "currentUser", "getExchangeRates", "getReadableRates", "isOldUser"]),
    },
    methods: {
        ...mapActions(["logOut"]),
        closeMenu() {
            this.isMenuOpened = false;
        },
        disconnect() {
            this.$showConfirmDialog({
                title: 'Выйти из аккаунта?',
                description: '',
                approvedCallback: async () => {
                    try {
                        await this.logOut();
                        this.$router.go();
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    header {
        background-color: #fff;
        box-shadow: $shadow;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 65px;
        z-index: 1;
        &.absolute,
        &.opened {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 99;
        }
        img {
            height: 25px;
            max-width: 100px;
            margin-right: 9px;
        }
        .menu-btn {
            display: none;
            flex: 1;
            max-width: none;
            cursor: pointer;
        }
        nav {
            flex: 1;
            padding: 0 40px;
            a {
                color: $text;
                text-shadow: 0px 1px 0px $border-color;
                padding: 5px;
                margin: 0 30px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .logo {
        font-size: 22px;
        display: flex;
        align-items: flex-end;
        color: $text;
        text-shadow: 0px 1px 0px $border-color;
        &:hover {
            text-decoration: none;
        }
        span {
            font-size: 22px;
            color: $second;
            padding: 0;
            margin: 0;
        }
    }

    .controls {
        display: flex;
        align-items: center;
        &>span {
            margin-left: 15px;
        }
        .rates {
            color: $text-regular;
            max-width: none;
        }
        &__btn {
            display: none;
        }
    }

    span {
        display: inline-flex;
        align-items: center;
        color: $text;
        text-shadow: 0px 1px 0px $border-color;
        font-size: 17px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        i {
            font-size: 24px;
            margin-right: 8px;
        }
    }

    @media screen and (max-width: 1750px) {
        header {
            padding: 0 25px;
        }
    }

    @media screen and (max-width: 1400px) {
        header {
            .logo, .logo span {
                font-size: 18px;
                line-height: 25px;
            }
            nav {
                padding: 0 0 0 20px;
                a {
                    margin: 0 15px;
                }
            }
        }
        .controls .rates {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        header {
            padding: 0 30px;
            .menu-btn {
                display: block;
            }
            nav {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                top: 40px;
                width: 100%;
                z-index: 99999;
                background: #fff;
                margin-left: -30px;
                padding: 0;
                height: 0;
                overflow: hidden;
                transition: height 0.3s;
                a {
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    padding: 20px 10px;
                    border-top: $border;
                    &:first-child {
                        border: none;
                    }
                }
            }
            nav.opened {
                position: fixed;
                height: calc(100vh - 40px);
            }
            .logo {
                &--landing {
                    display: none;
                }
            }
        }
        .controls {
            align-items: flex-start;

            &__btn {
                display: block;
            }
        }
    }

    @media screen and (max-width: 768px) {
        header {
            padding: 0 20px;
            nav {
                margin-left: -20px;
                a {
                    font-size: 18px;
                }
            }
        }
        .controls  {
            &__text {
                display: none;
            }
            &__logout i {
                margin-right: 0;
            }
        }
    }

    @media screen and (max-width: 375px) {
        header {
            .logo--short, .logo--short span {
                font-size: 0;
            }
        }
    }
</style>
