<template>
    <div class="operations" v-if="getOperationsByDay.length">
        <div class="menu">
            <i class="fal fa-calendar-alt"></i>
            <ul>
                <li :class="{ 'active' : (chartPeriod === 'month') }" @click="dateChanged('month')">за этот месяц</li>
                <li :class="{ 'active' : (chartPeriod === 'month30') }" @click="dateChanged('month30')">за 30 дней</li>
                <li :class="{ 'active' : (chartPeriod === 'all') }" @click="dateChanged('all')">за все время</li>
            </ul>
            <span>|</span>
            <ul>
                <li :class="{ 'active' : (chartFormat === 'day') }" @click="chartFormat = 'day'">по дням</li>
                <li :class="{ 'active' : (chartFormat === 'week') }" @click="chartFormat = 'week'">по неделям</li>
                <li :class="{ 'active' : (chartFormat === 'month') }" @click="chartFormat = 'month'">по месяцам</li>
            </ul>
            <i v-if="$route.name !== 'statistics'" @click="$emit('changeFormat')" class="fal fa-list-ul"></i>
        </div>
        <BarChart
            :options="getChartOptions"
            :chart-data="getChartData"
            :styles="getChartStyles"
            class="barChart"
            :class="{ 'loading' : isLoading }"
        >
        </BarChart>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BarChart from './BarChart.js';

export default {
    name: 'statistics',
    components: {
      BarChart
    },
    props: ['heightOffset'],
    data() {
        return {
            days: [
                'Воскресенье',
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота',
            ],
            months: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
            ],
            chartHeight: '600px',
            chartFormat: 'day',
            chartPeriod: 'month',
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters([ "getOperationsByDay",
                        "getOperationsByWeek",
                        "getOperationsByMonth",
                        "getAccounts",
                        "getSpending",
                        "getIncoming",
                        "currentUser",
        ]),
        getChartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
            }
        },
        getChartData() {
            let dates = [];
            let totalIncome = [];
            let totalSpend = [];

            if (this.chartFormat === 'day') {
                this.getOperationsByDay.forEach( operations => {
                    dates.unshift( this.getDate(operations[0].date) );
                    totalIncome.unshift( this.getTotal(operations, false) );
                    totalSpend.unshift( -this.getTotal(operations, true) );
                });
            } else if (this.chartFormat === 'week') {
                this.getOperationsByWeek.weeks.forEach( week => {
                    dates.unshift(
                        this.getDate(week[week.length - 1]) + ' - ' + this.getDate(week[0])
                    );
                });
                this.getOperationsByWeek.operations.forEach( operations => {
                    totalIncome.unshift( this.getTotal(operations, false) );
                    totalSpend.unshift( -this.getTotal(operations, true) );
                });
            } else {
                this.getOperationsByMonth.months.forEach( month => {
                    dates.unshift(this.getMonth(month[0]));
                });
                this.getOperationsByMonth.operations.forEach( operations => {
                    totalIncome.unshift( this.getTotal(operations, false) );
                    totalSpend.unshift( -this.getTotal(operations, true) );
                });
            }

            return {
                labels: dates,
                datasets: [{
                    label: 'Доходы',
                    data: totalIncome,
                    backgroundColor: '#2dd3aa',
                },
                {
                    label: 'Расходы',
                    data: totalSpend,
                    backgroundColor: '#d6d6d6',
                }]
            }
        },
        getChartStyles() {
            return {
                position: 'relative',
                height: this.chartHeight,
                top: '20px',
                margin: '0 auto 25px auto',
            }
        }
    },
    async mounted() {
        if (this.heightOffset) {
            this.chartHeight = (document.body.clientWidth < 1024) ? '300px' : `calc(100vh - ${this.heightOffset}px)`;
        } else {
            this.chartHeight = (document.body.clientWidth < 1024) ? '300px' : '600px';
        }
    },
    methods: {
        ...mapActions(["loadOperations","loadCategories"]),
        ...mapMutations(["DATE_CHANGED"]),
        async dateChanged(period) {
            this.isLoading = true;
            this.chartPeriod = period;
            let dates = [];
            let month = new Date();
            switch (period) {
                case 'month':
                    month.setDate(1);
                    dates = [ month, new Date() ];
                    break;
                case 'month30':
                    month.setMonth(month.getMonth() - 1);
                    dates = [ month, new Date() ];
                    break;
                case 'all':
                    dates = [ new Date(this.currentUser.createdAt), new Date() ];
                    break;
            }
            this.DATE_CHANGED(dates);
            try {
                await this.loadOperations(dates);
                this.isLoading = false;
                await this.loadCategories();
            } catch (err) {
                console.log(err);
            }
            this.isLoading = false;
        },
        getTotal(operations, isSpending) {
            let amounts = operations.map(operation => {
                if (operation.from === 'account' && operation.to === 'account') {
                    return 0;
                }
                return this.$convertToDefultCurrency(operation.currency, operation.amount); // mixin
            });

            if (isSpending) {
                amounts = amounts.filter((amount) => amount < 0);
            } else {
                amounts = amounts.filter((amount) => amount > 0);
            }

            return (amounts.length) ? amounts.reduce(( sum, amount ) => sum + amount).toFixed(2) : 0;
        },
        getDate(date) {
            if (this.chartFormat === 'day') {
                // add three hours becouse of MSK tmeZoneOffset (on server)
                date = new Date(new Date(date).getTime() + 3000 * 60 * 60);
                return this.days[date.getDay()] + ', ' + this.addZero(date.getDate()) + '.' + this.addZero(date.getMonth() + 1);
            } else {
                date = new Date(date);
                return this.addZero(date.getDate()) + '.' + this.addZero(date.getMonth() + 1);
            }
        },
        getMonth(date) {
            date = new Date(date);
            return this.months[date.getMonth()] + ' ' + date.getFullYear();
        },
        addZero(date) {
            return date < 10 ? '0' + date : date;
        }
    }
}
</script>

<style lang="scss">
    canvas {
        width: 100% !important;
    }

    .barChart {
        transition: opacity 0.3s ease;
        &.loading {
            opacity: 0.3;
        }
    }

    @media screen and (max-width: 610px) {
        .barChart {
            width: calc(100% - 30px);
        }
    }
</style>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    .operations {
        position: relative;
        width: 100%;
        text-align: left;
        font-size: 16px;
        p {
            font-size: 20px;
            padding: 0 20px;
        }
        .menu {
            position: relative;
            display: flex;
            align-items: center;
            padding-right: 50px;
            padding-top: 10px;
            span {
                color: $text;
                position: relative;
                top: -2px;
                padding: 0 10px;
            }
            ul {
                margin: 0;
                padding: 0;
                li {
                    font-size: 18px;
                    line-height: 22px;
                    color: $text-regular;
                    display: inline-block;
                    list-style-type: none;
                    padding: 0 8px;
                    margin: 0;
                    cursor: pointer;
                    &.active, &:hover {
                        color: $text;
                        border-bottom: 1px solid $text;
                    }
                }
            }
            &>i {
                font-size: 22px;
                padding: 0 20px;
                color: $text;
                z-index: 99;
            }
            i:first-child {
                padding-right: 15px;
            }
            i:last-child {
                cursor: pointer;
                position: absolute;
                right: 0;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .operations .menu {
            padding-top: 0;
        }
    }

    @media screen and (max-width: 610px) {
        .operations .menu ul {
            text-align: center;
        }
    }

    @media screen and (max-width: 425px) {
        .operations .menu {
            ul li {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            span {
                padding: 0;
            }
            i:first-child {
                padding: 0 10px 0 10px;
            }
        }
    }
</style>
