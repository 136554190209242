import getDb from './getDb.js';

export default {
    async getDebts() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['debts'],'readonly');
            trans.oncomplete = () => {
                resolve(debts);
            };

            let store = trans.objectStore('debts');
            let debts = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    debts.push(cursor.value);
                    cursor.continue();
                }
            };
        });
    },
    async saveDebts(debts) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['debts'],'readwrite');
            trans.oncomplete = () => {
                resolve(debt);
            };

            let store = trans.objectStore('debts');
            debts.forEach(debt => {
                store.put(debt);
            });
            let debt;
            if (debts.length === 1) {
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        debt = cursor.value;
                        cursor.continue();
                    }
                };
            }
        });
    },
    async deleteDebts(id) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['debts'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('debts');
            if (id) {
                store.delete(Number(id));
            } else {
                store.clear();
            }
        });
    }
}