<template>
    <div>
        <form
            v-show="!isCategoryRoute"
            :class="{ 'open' : isFormOpen, 'focused': editOperationId }"
            @submit.prevent="addOperationBtn()"
        >
            <div class="label select">из
                <v-select
                    v-model="from"
                    @change="categorySelected()"
                    :items="(getFromHiddenItem) ? [ ...getFromItems, getFromHiddenItem] : getFromItems"
                    :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                    label="- Откуда -"
                    solo
                    flat
                >
                    <template v-slot:selection="{ item }" >
                        <v-list-item-icon class="my-auto mr-2">
                            <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                            <v-icon class="ma-auto success--text" v-if="item.value.includes('income')">fal fa-dollar-sign</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="accent--text">{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:item="{ item }" >
                        <v-list-item-icon class="my-auto mr-4">
                            <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                            <v-icon class="ma-auto success--text" v-if="item.value.includes('income')">fal fa-dollar-sign</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </div>
            <div class="label select"> в
                <v-select
                    v-model="to"
                    @change="categorySelected()"
                    :items="(getToHiddenItem) ? [ ...getToItems, getToHiddenItem] : getToItems"
                    :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                    label="- Куда -"
                    solo
                    flat
                >
                    <template v-slot:selection="{ item }" >
                        <v-list-item-icon class="my-auto mr-2">
                            <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                            <v-icon class="ma-auto red--text" v-if="item.value.includes('spend')">fal fa-shopping-cart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="accent--text">{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:item="{ item }" >
                        <v-list-item-icon class="my-auto mr-4">
                            <v-icon class="ma-auto orange--text" v-if="item.value.includes('account')">fal fa-university</v-icon>
                            <v-icon class="ma-auto red--text" v-if="item.value.includes('spend')">fal fa-shopping-cart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </div>
            <div class="label label-amount" title="cумма списания со счета">
                <input ref="amount" v-model="amount" type="number" step="any" placeholder="2 300.99" @keypress='validateAmout($event)'>
                <span >{{ $getCurrencySign(currency) }}</span>
            </div>
            <div class="label label-amount2" v-if="isExchange" title="cумма поступившая на счет (с учетом комиссии/конвертации)">
                <i class="mx-4 far fa-long-arrow-right"></i>
                <input v-model="amount2" type="number" step="any" placeholder="2 299.41" @keypress='validateAmout($event)'>
                <span >{{ $getCurrencySign(currency2) }}</span>
            </div>
            <div class="label">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="computedDateFormatted"
                            readonly
                            v-on="on"
                            class="calendar calendar--inline"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        locale="ru"
                        :first-day-of-week="1"
                        @input="menu = false"
                    ></v-date-picker>
                </v-menu>
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="time"
                            readonly
                            v-on="on"
                            class="calendar calendar--inline-time"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu2"
                        v-model="time"
                        no-title
                        @click:minute="$refs.menu.save(time)"
                    ></v-time-picker>
                </v-menu>
            </div>
            <div class="comment label">
                <i class="fal fa-pen-alt"></i>
                <input v-model="comment" type="search" placeholder="Комментарий">
            </div>
            <div class="addBtn">
                <i
                    v-if="editOperationId || isFormDirty"
                    :title="editOperationId ? 'Отмена' : 'Очистить'"
                    class="far fa-times"
                    @click="clearForm"
                ></i>
                <half-circle-spinner
                    v-if="isLoading"
                    class="loading"
                    :animation-duration="1000"
                    :size="15"
                    :color="'#FFF'"
                />
                <input v-if="editOperationId" class="add" type="submit" value="Сохранить">
                <input v-else class="add" type="submit" value="Добавить">
            </div>
            <div
                v-if="editOperationId || isFormDirty"
                class="cancelBtn"
                @click="clearForm(editOperationId)"
            >
                <input type="submit" class="second" :value="editOperationId ? 'Отмена' : 'Очистить'">
            </div>
        </form>
        <transition name="fade">
            <div v-if="error" class="error">
                {{ errorMsg || 'Пожалуйста заполните все обязательные поля!' }}
                <i @click="error = false" class="far fa-times"></i>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="isCachedOperations" class="error">
                {{ 'Проблемы с подключением, операции грузятся из кэша! При возобновлении соединения, пожалуйста нажмите кнопку ' }}
                <span @click="updateCachedOperations">"Обновить"</span>
                <i title="Обновить" @click="updateCachedOperations" class="far fa-sync"></i>
            </div>
        </transition>
        <div v-show="!isCategoryRoute" class="newOperation" @click="openAddOperationForm">
            <p v-if="isFormOpen && !editOperationId"><i class="far fa-chevron-up"></i> Свернуть</p>
            <p v-else-if="!isFormOpen" @click="prepareDates"><i class="far fa-plus"></i> Добавить операцию</p>
        </div>
        <transition name="dataFormat" mode="out-in">
            <spinner v-if="isMounting" />
            <h5 v-else-if="!getOperationsByDay.length">За этот период нет записей!</h5>
            <List
                v-else-if="!isChart"
                @changeFormat="isChart = !isChart"
                @editOperationBtn="editOperationBtn"
            />
            <Statistics v-else @changeFormat="isChart = !isChart" :heightOffset="(isCategoryRoute) ? 240 : 320"/>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import List from '@/components/List';
import Statistics from '@/components/Statistics';
import { HalfCircleSpinner } from 'epic-spinners';
import EventBus from '@/event-bus';

export default {
    name: 'finances',
    components: {
        List,
        Statistics,
        HalfCircleSpinner
    },
    data() {
        return {
            menu: false,
            menu2: false,
            lang: 'ru',
            date: null,
            time: null,
            from: '',
            to: '',
            amount: null,
            amount2: null,
            currency: 'UAH',
            currency2: 'UAH',
            comment: '',
            error: false,
            errorMsg: '',
            isLoading: false,
            isMounting: true,
            isExchange: false,
            isFormOpen: false,
            isChart: false,
            editOperationId: null,
            getFromHiddenItem: null,
            getToHiddenItem: null,
            isCachedOperations: false,
            isCategoryRoute: false,
        }
    },
    computed: {
        ...mapGetters([
            "getAccounts",
            "getSpending",
            "getIncoming",
            "getOperations",
            "getOperationsByDay",
            "currentUser",
            "getServerTimezoneOffset"
        ]),
        getFromItems() {
            return [
                ...this.arrayToOptions(this.getAccounts, 'account'),
                ...this.arrayToOptions(this.getIncoming, 'income')
            ];
        },
        getToItems() {
            if (this.from.includes('income')) {
                return [
                    ...this.arrayToOptions(this.getAccounts, 'account')
                ];
            } else {
                let accounts = this.getAccounts;

                if (this.from && this.from.split('/')[0] === 'account') {
                    let accountFromId = parseInt(this.from.split('/')[1]);

                    accounts = this.getAccounts.filter(account => account.id !== accountFromId)
                }

                return [
                    ...this.arrayToOptions(this.getSpending, 'spend'),
                    ...this.arrayToOptions(accounts, 'account')
                ];
            }
        },
        computedDateFormatted() {
            if (!this.date) return null;

            const [year, month, day] = this.date.split('-');
            return `${day}.${month}.${year}`;
        },
        isFormDirty() {
            return this.from || this.to || this.amount;
        },
        clearFormAfterAdding() {
            return this.currentUser.settings?.clearFormAfterAdding;
        }
    },
    async mounted() {
        EventBus.$on('OPEN_ADD_OPERATION_FORM', this.openAddOperationForm);
        if (this.$route.name === "category") this.isCategoryRoute = true;
        if (this.$route.params.openForm) setTimeout(this.openAddOperationForm, 0);
        this.currency = this.currentUser.currency;
        this.currency2 = this.currentUser.currency;
        this.prepareDates();

        if (!this.getOperations.length) {
            try {
                await this.loadOperations();
                this.isLoading = false;
            } catch (err) {
                console.log(err);
            }
        } else {
            if (this.$route.name === 'finances') this.OPERATIONS_FILTERED([false, false]);
            this.isLoading = false;
        }

        this.isMounting = false;
    },
    beforeDestroy() {
        EventBus.$off('OPEN_ADD_OPERATION_FORM', this.openAddOperationForm);
    },
    methods: {
        ...mapActions(["addOperation","editOperation","loadOperations","getCategoryName","checkCachedOperations"]),
        ...mapMutations(["OPERATIONS_FILTERED"]),

        prepareDates(date, time) {
            // Date is added with timezone offset, because 'toISOString' counts in UTM
            // TODO: make it with moment, the same on Debts.vue & Calendar.vue
            if (date && time) {
                this.date = new Date(new Date(date).getTime() + this.getServerTimezoneOffset * 3600000).toISOString().substr(0, 10);
                this.time = time.substr(0, 5);
            } else {
                this.date = new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                this.time = new Date().toLocaleTimeString('en-GB').substr(0, 5);
            }
        },
        validateAmout(evt) {
            let theEvent = evt || window.event;
            // Handle paste
            let key;
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
                // Handle key press
                key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            let regex = /[0-9]|\.|,|\*/;
            if (!regex.test(key) ) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
            }
        },
        categorySelected() {
            if (this.from && this.to && this.from.split('/')[0] === 'account' && this.to.split('/')[0] === 'account') {
                this.currency = this.from.split('/')[2];
                this.currency2 = this.to.split('/')[2];
                this.isExchange = true; // show second amount input
            } else if (this.from) {
                this.isExchange = false; // hide second amount input
                this.currency = this.from.split('/')[2];
                if (this.from.split('/')[0] === 'income') { // if 'from' is income
                    if (this.to && this.to.split('/')[0] === 'spend') { // and if 'to' selected and it's 'spend'
                        this.to = ''; // cancel selected 'to'
                    }
                }
            }
            if (this.from && this.to && !this.amount) this.$refs['amount'].focus();
        },
        delay (time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },
        async addOperationBtn() {
            let operationsCount = (this.amount && this.amount.lastIndexOf('*') > 0) ? this.amount.slice(this.amount.lastIndexOf('*') + 1) : 1;

            this.isLoading = true;

            if (!this.amount) {
                if (this.isExchange && this.amount2) {
                    this.amount2 = parseFloat(String(this.amount2).replace(',','.').replace(' ',''));
                    this.amount = this.$convertToDefultCurrency(this.currency2, this.amount2, this.currency);
                } else {
                    this.error = true;
                    this.isLoading = false;
                    this.$refs['amount'].focus();
                    return;
                }
            } else {
                this.amount = parseFloat(String(this.amount).replace(',','.').replace(' ',''));
            }

            if (this.isExchange && this.amount2) {
                this.amount2 = parseFloat(String(this.amount2).replace(',','.').replace(' ',''));
            } else {
                this.amount2 = null;
            }

            if (this.from === '' || this.to === '' || isNaN(this.amount) || ( this.isExchange && isNaN(this.amount2) )) {
                this.error = true;
                this.isLoading = false;
                return;
            } else {
                this.error = false;
            }

            let operationObj = {
                date: this.date,
                time: this.time + new Date().toLocaleTimeString('en-GB').substr(5, 8),
                from: this.from.split('/')[0],
                fid: this.from.split('/')[1],
                to: this.to.split('/')[0],
                tid: this.to.split('/')[1],
                amount: (this.to.split('/')[0] === 'spend') ? -this.amount : this.amount,
                amount2: this.amount2,
                currency: this.currency,
                currency2: this.currency2,
                comment: this.comment
            };


            try {
                if (operationsCount > 1 && !this.editOperationId) {
                    let operations = [];
                    for (let i = 0; i < operationsCount; i++) {
                        operations.push(operationObj);
                    }
                    for (let operation of operations) {
                        await this.addOperation(operation);
                        await this.delay(1001);
                    }
                } else {
                    this.editOperationId ?
                        await this.editOperation({ params: operationObj, id: this.editOperationId }) :
                        await this.addOperation(operationObj);
                }

                if (this.clearFormAfterAdding || this.editOperationId) {
                    this.clearForm();
                } else {
                    this.amount = null;
                    this.amount2 = null;
                }
                this.OPERATIONS_FILTERED([false, false]);
                await this.updateCachedOperations();
            } catch (error) {
                this.error = true;
                this.errorMsg = error;
            }
            this.isLoading = false;
        },
        arrayToOptions(categories, categoryName) {
            return categories.map(category => {
                return {
                    text: category.name,
                    value: '' + categoryName + '/' + category.id + '/' + category.currency + ''
                }
            })
        },
        async editOperationBtn(operation) {
            this.isCategoryRoute = false;
            this.isFormOpen = true;
            $('html, body').animate({ scrollTop: 0 }, 300);
            (operation.from === operation.to) ? this.isExchange = true : this.isExchange = false;
            this.getFromHiddenItem = null;
            this.getToHiddenItem = null;

            this.editOperationId = operation.id;
            this.prepareDates(operation.date, operation.time);
            this.from = '' + operation.from + '/' + operation.fid + '/' + operation.currency + '';
            this.to = '' + operation.to + '/' + operation.tid + '/' + (operation.currency2 || operation.currency) + '';
            this.amount = String(Math.abs(operation.amount));
            this.amount2 = String(Math.abs(operation.amount2));
            this.currency = operation.currency;
            this.currency2 = operation.currency2;
            this.comment = operation.comment;

            // check if categories are not hidden
            if (!this.getFromItems.find(item => item.value === this.from)) {
                let fromName = await this.getCategoryName({ category: operation.from, id: operation.fid });
                this.getFromHiddenItem = {
                    text: fromName,
                    value: this.from
                }
            }
            if (!this.getToItems.find(item => item.value === this.to)) {
                let toName = await this.getCategoryName({ category: operation.to, id: operation.tid });
                this.getToHiddenItem = {
                    text: toName,
                    value: this.to
                }
            }
        },
        clearForm(collapseForm = true) {
            if (this.$route.name === "category") this.isCategoryRoute = true;
            if (collapseForm) this.isFormOpen = false;
            this.date = new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
            this.time = new Date().toLocaleTimeString('en-GB').substr(0, 5);
            this.from = '';
            this.to = '';
            this.amount = null;
            this.amount2 = null;
            this.currency = this.currentUser.currency;
            this.comment = '';
            this.isExchange = false;
            this.editOperationId = null;
            this.getFromHiddenItem = null;
            this.getToHiddenItem = null;
        },
        async updateCachedOperations() {
            this.isLoading = true;
            let cachedOperations = await this.checkCachedOperations();

            for (let [i, operation] of cachedOperations.entries()) {
                try {
                    if (i) await this.delay(1001); // if this is not first operation - wait 1 sec
                    operation.date = operation.cachedDate;
                    await this.addOperation(operation);
                    this.isCachedOperations = false;
                    this.OPERATIONS_FILTERED([false, false]);
                } catch (error) {
                    // if cache doesn't updated - show error
                    this.isLoading = false;
                    this.isCachedOperations = true;
                    console.log(error);
                    return false;
                }
            }
            this.isLoading = false;
            EventBus.$emit('UPDATE_BANKS_OPERATIONS');
        },
        openAddOperationForm() {
            if (this.isFormOpen) {
                this.isFormOpen = false;
            } else {
                this.isFormOpen = true;
                this.$refs['amount'].focus();
            }
        }
    },
    watch: {
        error: function (value) {
            if (!value) return;
            $('html, body').animate({
                scrollTop: $(document).scrollTop() + 36,
            }, 300);
        },
        amount: function (value) {
            this.amount2 = this.$convertToDefultCurrency(this.currency, value, this.currency2).toFixed(2);
        },
        from: function (value) {
            if (value.split('/')[0] === 'income' && this.getAccounts.length === 1) {
                this.to = 'account/' + this.getAccounts[0].id + '/' + this.getAccounts[0].currency;
            } else if (value.split('/')[0] === 'account' && this.getSpending.length === 1) {
                this.isExchange = false; // hide second amount input
                this.to = 'spend/' + this.getSpending[0].id + '/' + this.getSpending[0].currency;
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/finances.scss';
</style>
<style lang="scss">
    @import '@/assets/vars.scss';

    .v-list-item__icon .v-icon.v-icon {
        font-size: 20px;
    }
    .v-list .v-list-item {
        min-height: unset;
    }
    .v-list .v-list-item__content {
        padding: 8px 0;
    }

    .select, .operations {
        .v-select.v-text-field input {
            flex: 0 0;
        }
        .v-select__selections {
            justify-content: center;
            i {
                font-size: 20px;
            }
        }
        .v-list-item__content {
            flex: 0 1 auto;
            max-width: 100%;
        }
        .v-label {
            transform: translateX(calc(-50% - 10px));
            left: 50% !important;
        }
        .v-input__icon .v-icon {
            color: $text;
        }
    }
</style>
