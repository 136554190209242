import Vue from 'vue';
import idb from './indexedDB/index.js';
import validateError from './mixins/validateError';

export default {
    state: {
        operations: [],
        filteredOperations: [],
    },
    getters: {
        getOperations(state) {
            return state.operations || state.filteredOperations; // if only one operation just added to state - return filteredOperations
        },
        getOperationsByDay(state) {
            let dates = new Set(); // collecton of unic dates
            let sortedByDay = [];
            state.filteredOperations.sort((a, b) => new Date(b.date) - new Date(a.date)); // sort by date
            // make collection of dates
            state.filteredOperations.forEach((operation) => {
                dates.add(operation.date);
            });
            // make collection of arrays with unic operation date
            dates.forEach((date) => {
                let operationsByDay = state.filteredOperations.filter(operation => date === operation.date);
                operationsByDay.sort((a, b) => { // sort by time
                    return (a.time.slice(0,2) === b.time.slice(0,2)) ? a.time.slice(3,5) - b.time.slice(3,5) : a.time.slice(0,2) - b.time.slice(0,2);
                });
                sortedByDay.push(operationsByDay);
            });

            return sortedByDay;
        },
        getOperationsByWeek(state) {
            let dates = new Set(); // collecton of unic dates
            let weeks = [], week = []; // collecton of weeks
            let sortedByWeek = [];
            // make collection of dates
            state.filteredOperations.forEach((operation) => {
                dates.add(operation.date);
            });

            // make array of weeks
            dates = Array.from(dates);
            // add three hours becouse of MSK tmeZoneOffset (on server)
            let latestDate = new Date(new Date(dates[0]).getTime() + 3000 * 60 * 60);
            let lastDate = new Date(new Date(dates[dates.length - 1]).getTime() + 3000 * 60 * 60);
            latestDate.setHours(0);
            lastDate.setHours(0);
            while ( latestDate >= lastDate ) {
                let date = new Date(latestDate);
                if (date.getDay() > 0) {
                    week.push(date);
                } else { // if day = Sunday
                    if (week.length) weeks.push(week);
                    week = [];
                    week.push(date);
                }
                latestDate = latestDate - 24*60*60*1000; // minus day every cycle
            }
            weeks.push(week);
            // make collection of arrays with unic operation week
            weeks.forEach((week) => {
                let currentWeekOperations = [];
                week.forEach((date) => {
                    currentWeekOperations = currentWeekOperations.concat(
                        state.filteredOperations.filter((operation) => {
                            let operationDate = new Date(new Date(operation.date).getTime() + 3000 * 60 * 60);
                            operationDate.setHours(0);

                            return (date.setHours(0) - operationDate === 0) ? true : false;
                        })
                    );
                });
                sortedByWeek.push(currentWeekOperations);
            });

            return { weeks: weeks, operations: sortedByWeek };
        },
        getOperationsByMonth(state) {
            let dates = new Set(); // collecton of unic dates
            let months = [], month = []; // collecton of weeks
            let sortedByMonth = [];
            // make collection of dates
            state.filteredOperations.forEach((operation) => {
                dates.add(operation.date);
            });

            // make array of weeks
            dates = Array.from(dates);
            let latestDate = new Date(new Date(dates[0]).getTime() + 3000 * 60 * 60);
            let lastDate = new Date(new Date(dates[dates.length - 1]).getTime() + 3000 * 60 * 60);
            latestDate.setHours(0);
            lastDate.setHours(0);
            while ( latestDate >= lastDate ) {
                let date = new Date(latestDate);
                if (date.getDate() !== 1) {
                    month.push(date);
                } else { // if day = first day of a month
                    month.push(date);
                    months.push(month);
                    month = [];
                }
                latestDate = latestDate - 24*60*60*1000; // minus day every cycle
            }
            if (month.length) months.push(month);
            // make collection of arrays with unic operation week
            months.forEach((month) => {
                let currentMonthOperations = [];
                month.forEach((date) => {
                    currentMonthOperations = currentMonthOperations.concat(
                        state.filteredOperations.filter((operation) => {
                            let operationDate = new Date(new Date(operation.date).getTime() + 3000 * 60 * 60);
                            operationDate.setHours(0);

                            return (date.setHours(0) - operationDate === 0) ? true : false;
                        })
                    );
                });
                sortedByMonth.push(currentMonthOperations);
            });

            return { months: months, operations: sortedByMonth };
        }
    },
    actions: {
        async loadOperations({ commit, rootState}, dates) {
            if (dates) {
                dates[0].setHours(rootState.serverTimezoneOffset);
                dates[1].setHours(rootState.serverTimezoneOffset);
                commit('DATE_CHANGED', dates);
            } else {
                rootState.dates[0].setHours(rootState.serverTimezoneOffset);
                rootState.dates[1].setHours(rootState.serverTimezoneOffset);
            }

            if (!navigator.onLine) { // if app is offline
                let operations = await idb.getOperations(rootState.dates);
                commit("OPERATIONS_FETCHED", operations || []);
                return false;
            }

            try {
                const res = await Vue.axios.get("/api/operations", { params: { dates: rootState.dates }, timeout: 3500 });
                commit("OPERATIONS_FETCHED", res.data.operations);
                await idb.saveOperations(res.data.operations);
            } catch (error) {
                let operations = await idb.getOperations(rootState.dates);
                commit("OPERATIONS_FETCHED", operations || []);
            }
        },
        async delOperation({ commit }, id) {
            try {
                await Vue.axios.delete("/api/operations/delete", { params: { id } });
                commit("OPERATION_DELETED", id);
                await idb.deleteOperations(id);
            } catch (error) {
                throw validateError(error);
            }
        },
        async addOperation({ commit, rootState }, params) {
            let res = { data: {} };
            let isCachedOperation = false; // indicates that new balances should be write to Postgre but not in current state
            try {
                res = await Vue.axios.post("/api/operations/add", params, { timeout: 3500 });
                if (params.cached) { // if operation adds from cache
                    isCachedOperation = true;
                    commit("OPERATION_DELETED", params.id);
                    await idb.deleteOperations(params.id);
                }
                commit('OPERATION_ADDED', res.data);
                await idb.saveOperations([ res.data ]);
            } catch (error) {
                if (params.cached || params.imported) throw error; // if server doesn't availiable and current operation already in cache || from bank
                params.cached = true; // if operation not in cache yet
                params.cachedDate = params.date;
                // fix date after added to caсhe (may cause a bug because of timezone)
                params.date = new Date(params.date).toISOString();
                let cachedOperation = await idb.saveOperations([ params ]);
                commit("OPERATION_ADDED", cachedOperation);
                res.data = params; // update categories with cached operation
            }

            // if operation was added from API - don't update state
            if (res.data.from === 'waitSelection' || res.data.to === 'waitSelection') return;

            let newBalance, newBalance2;
            if (isCachedOperation) { // indicates that new balances should be write to Postgre but not in current state
                // TODO: rsolve case, when user open app and categories loads from idb with old balances,
                // in this case balance should be updated even if operation loads from cash
                newBalance = 0;
                newBalance2 = (res.data.amount2) ? 0 : null;
            } else {
                newBalance = Number(res.data.amount);
                newBalance2 = (res.data.amount2) ? Number(res.data.amount2) : null;
            }

            let accountId;
            if (res.data.from === 'account' && res.data.to === 'spend') {
                accountId = res.data.fid;
            } else if (res.data.from === 'income' && res.data.to === 'account') {
                accountId = res.data.tid;
            } else if (res.data.from === 'account' && res.data.to === 'account') {
                accountId = res.data.tid;
                // Change balance of account from which was charged money
                let accountToChange = rootState.Categories.accounts.filter( account => account.id === Number(res.data.fid) )[0];
                accountToChange.balance = (Number(accountToChange.balance) - newBalance).toFixed(2);
                try {
                    const account = await Vue.axios.post("/api/categories/edit/account",
                        { ...accountToChange, categoryId: accountToChange.id },
                        { timeout: 3500 }
                    );
                    if (!isCachedOperation) commit("CATEGORY_EDITED", [ account.data, 'account' ]);
                } catch (error) {
                    commit("CATEGORY_EDITED", [{ ...accountToChange, categoryId: accountToChange.id }, 'account' ]);
                }
            }

            // Change balance of account from or to which was charged money
            let accountToChange = rootState.Categories.accounts.find( account => account.id === Number(accountId) );
            accountToChange.balance = (Number(accountToChange.balance) + (newBalance2 || newBalance)).toFixed(2);
            try {
                const account = await Vue.axios.post("/api/categories/edit/account",
                    { ...accountToChange, categoryId: accountToChange.id }
                );
                if (!isCachedOperation) commit("CATEGORY_EDITED", [ account.data, 'account' ]);
            } catch (error) {
                commit("CATEGORY_EDITED", [{ ...accountToChange, categoryId: accountToChange.id }, 'account' ]);
            }
        },
        async editOperation({ commit }, params) {
            try {
                const res = await Vue.axios.post("/api/operations/edit", params, { timeout: 3500 });
                commit('OPERATION_EDITED', res.data);
                await idb.deleteOperations(params.id);
                await idb.saveOperations([ res.data ]);
            } catch (error) {
                throw validateError(error);
            }
        },
        async checkCachedOperations () {
            let operations = await idb.getOperations();
            return operations.filter(operation => operation.cached);
        }
    },
    mutations: {
        OPERATIONS_FETCHED (state, operations) {
            state.operations = operations;
            state.filteredOperations = operations;
        },
        OPERATIONS_FILTERED (state, [ name, id ]) {
            if (name && id) {
                state.filteredOperations = state.operations.filter( operation => {
                    return operation.from === name && operation.fid === id || operation.to === name && operation.tid === id;
                });
            } else {
                state.filteredOperations = state.operations;
            }
        },
        OPERATION_ADDED (state, operation) {
            state.operations.push(operation);
        },
        OPERATION_DELETED (state, id) {
            state.operations = state.operations.filter( operation => operation.id !== id );
            state.filteredOperations = state.filteredOperations.filter( operation => operation.id !== id );
        },
        OPERATION_EDITED (state, editedOperation) {
            state.operations = state.operations.map((operation) => {
                if (operation.id === editedOperation.id) {
                    return editedOperation;
                } else {
                    return operation;
                }
            });
            state.filteredOperations = state.filteredOperations.map((operation) => {
                if (operation.id === editedOperation.id) {
                    return editedOperation;
                } else {
                    return operation;
                }
            });
        },
        OPERATIONS_SEARCH (state, searchStr) {
            if (searchStr) {
                state.filteredOperations = state.operations.filter( operation => {
                    return operation.comment.toLowerCase().includes(searchStr.toLowerCase());
                });
            } else {
                state.filteredOperations = state.operations;
            }
        }
    }
}
