<template>
  <v-app id="app">
    <Header />
    <div v-if="loading" class="spinner-wrap">
        <half-circle-spinner
            :animation-duration="1000"
            :size="30"
            :color="'#3E2BCE'"
        />
    </div>
    <Landing v-if="!isAuthenticated && loading == false && !isPolicyOrTerms" />
    <transition name="fade" mode="out-in">
        <router-view v-if="isLogin && loading == false" />
    </transition>
    <MenuMobile v-if="isAuthenticated" />
    <VueDialog />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { HalfCircleSpinner } from 'epic-spinners';
import Header from '@/components/Header.vue';
import MenuMobile from '@/components/MenuMobile.vue';
import Landing from '@/pages/Landing';
import VueDialog from '@/components/gloabal/VueDialog';

export default {
    name: 'app',
    components: {
        HalfCircleSpinner,
        Header,
        Landing,
        VueDialog,
        MenuMobile
    },
    async mounted() {
        this.SCREEN_WIDTH_CHANGED(window.innerWidth);

        try {
            await this.initialLoad();
            if (this.isAuthenticated) {
                await this.loadExchangeRates();
                await this.loadCategories();
                await this.loadOperations();
                await this.updateCacheData();
            }
        } catch (err) {
            console.log(err);
        }
        // updated data when returned to window
        this.$nextTick(() => {
            window.document.querySelector('body').onblur = async () => {
                this.afkTime = new Date();
            };
            window.document.querySelector('body').onfocus = async () => {
                // if user wasn't on the page more than hour
                if (this.isAuthenticated && new Date() - this.afkTime > 60*60*1000) {
                    location.reload(); // reload page to update all data
                }
            };
        });
        this.loading = false;

        window.addEventListener("resize", () => {
            this.SCREEN_WIDTH_CHANGED(window.innerWidth);
        })
    },
    data: function () {
        return {
            loading: true,
            afkTime: new Date(),
        }
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        isLogin() {
            return  this.isAuthenticated ||
                    this.$route.name === 'login' ||
                    this.$route.name === 'registration' ||
                    this.$route.name === 'foregetpass' ||
                    this.$route.name === 'restorepass' ||
                    this.$route.name === 'social-registration' ||
                    this.$route.name === 'ios' ||
                    this.$route.name === 'privacy-policy' ||
                    this.$route.name === 'terms-of-service' ||
                    this.$route.name === 'android';
        },
        isPolicyOrTerms() {
            return this.$route.name === 'privacy-policy' || this.$route.name === 'terms-of-service';
        },
    },
    methods: {
        ...mapActions(["initialLoad",
                    "loadExchangeRates",
                    "checkCachedDebts",
                    "checkCachedOperations",
                    "addOperation",
                    "loadOperations",
                    "loadCategories"]),
        ...mapMutations(["DATE_CHANGED", "SCREEN_WIDTH_CHANGED"]),
        delay (time) {
            return new Promise(resolve => setTimeout(resolve, time));
        },
        async updateCacheData() {
            let cachedOperations = await this.checkCachedOperations();

            for (let [i, operation] of cachedOperations.entries()) {
                try {
                    if (i) await this.delay(1001); // if this is not first operation - wait 1 sec
                    operation.date = operation.cachedDate;
                    await this.addOperation(operation);
                } catch (error) {
                    console.log(error);
                    return false;
                }
            }

            this.checkCachedDebts();
        }
    }
}
</script>


<style lang="scss">
    .spinner-wrap {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background: rgba(255,255,255,0.3);
        width: 100%;
        height: 100%;
        z-index: 9999;
    }

</style>
