<template>
    <div class="body">

    <!-- Hero section -->
    <section class="hero-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6 hero-text">
                    <h1>Учет личных <span>Средств -</span><br>Финансовый успех!</h1>
                    <h4>Бесплатный удобный сервис для учета трат и доходов</h4>
                    <div class="hero-subscribe-from">
                        <button class="site-btn sb-gradients" @click="$router.push('/registration')">Попробовать</button>
                        <span>
                            <a
                                class="fab fa-android"
                                title="Установить на Android"
                                href="https://play.google.com/store/apps/details?id=com.splitmyfunds.www"
                                target="_blank"
                            ></a>
                            <i @click="$router.push('/install-pwa-instructions')" title="Установить на iOS" class="fab fa-apple"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="/img/laptop.png" class="laptop-image" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- Hero section end -->

    <!-- About section -->
    <section class="about-section spad" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-6 about-text">
                    <h2>Зачем это нужно?</h2>
                    <h5>Учет расходов помогает экономить и избегать лишних трат</h5>
                    <p>Поделив на категории свои расходы, в конце месяца можно посмотреть статистику, на что тратились деньги.
                        Это позволит выделить для себя не нужные расходы и спланировать оптимальный бюджет на следующий месяц.
                    </p>
                    <a @click="$router.push('/registration')" class="site-btn sb-gradients sbg-line mt-5">Регистрация</a>
                </div>
            </div>
            <div class="about-img">
                <img src="/img/about-img.png" alt="">
            </div>
        </div>
    </section>
    <!-- About section end -->


    <!-- Features section -->
    <section class="features-section spad gradient-bg" id="features">
        <div class="container text-white">
            <div class="section-title text-center">
                <h2>Что мы предлагаем?</h2>
                <p>SplitMyFunds - это простой способ вести учет расходов и доходов.</p>
            </div>
            <div class="row">
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-coins"></i>
                    <div class="feature-content">
                        <h4>Счета</h4>
                        <p>Держите информацию о ваших счетах в одном месте, это позволит в любой момент получить актуальные данные.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-chart-bar"></i>
                    <div class="feature-content">
                        <h4>Расходы и Доходы</h4>
                        <p>Разбейте ваши расходы и доходы на категории, анализируйте статистику, планируйте бюджет на следующий месяц.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-euro-sign"></i>
                    <div class="feature-content">
                        <h4>Долги</h4>
                        <p>Удобный способ записи долгов. Держите под рукой доступ к информации о ваших долгах, и сколько должны вам.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-users smaller"></i>
                    <div class="feature-content">
                        <h4>Общие средства</h4>
                        <p>Ведите учет совместных средств. Создайте общий счет или категорию трат с членами семьи или бизнес-партнерами.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-university"></i>
                    <div class="feature-content">
                        <h4>Импорт из банков</h4>
                        <p>Привяжите реальные счета к сервису, чтобы автоматически подтягивать операции со всех доступных банков.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-wifi-slash smaller"></i>
                    <div class="feature-content">
                        <h4>Оффлайн режим</h4>
                        <p>Все данные кэшируются, поэтому можно использовать приложение даже без подключения к Интернету.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-phone-laptop smaller"></i>
                    <div class="feature-content">
                        <h4>Адаптивность</h4>
                        <p>Сервис полностью адаптивный. Это значит, что его можно использовать на любом устройстве и о.с.</p>
                    </div>
                </div>
                <!-- feature -->
                <div class="col-md-6 col-lg-4 feature">
                    <i class="fal fa-shield-check"></i>
                    <div class="feature-content">
                        <h4>Безопастность</h4>
                        <p>Личные данные полностью защищены! Сервис отвечает всем требованиям к безопасности финансовых систем.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Features section end -->


    <!-- Process section -->
    <section class="process-section spad" id="process">
        <div class="container">
            <div class="section-title text-center">
                <h2>Как начать вести учет?</h2>
                <p>Начните вести учет средств вместе с SplitMyFunds. Это просто, удобно, и не занимает много времени!</p>
            </div>
            <div class="row">
                <div class="col-md-4 process">
                    <div class="process-step">
                        <figure class="process-icon">
                            <img src="/img/process-icons/1.png" alt="#">
                        </figure>
                        <h4>Добавьте счета</h4>
                        <p>Это могут быть условные счета, например: кошелек, наличные. А также реальные счет в банке: кредитка, депозит.</p>
                    </div>
                </div>
                <div class="col-md-4 process">
                    <div class="process-step">
                        <figure class="process-icon">
                            <img src="/img/process-icons/2.png" alt="#">
                        </figure>
                        <h4>Создайте категории</h4>
                        <p>Разделите на категории ваши расходы: продукты, поездки, коммуналка и т.п. А также доходы: стипендия, зарплата.</p>
                    </div>
                </div>
                <div class="col-md-4 process">
                    <div class="process-step">
                        <figure class="process-icon">
                            <img src="/img/process-icons/3.png" alt="#">
                        </figure>
                        <h4>Ведите учет</h4>
                        <p>Вносите информацию о тратах, доходах и долгах, просматривайте статистику по категориям, планируйте бюджеты.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Process section end -->


    <!-- Fact section -->
    <section class="fact-section gradient-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    <div class="fact">
                        <h2>1</h2>
                        <p>Расходы</p>
                        <i class="fal fa-money-bill-wave"></i>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    <div class="fact">
                        <h2>2</h2>
                        <p>Учет</p>
                        <i class="fal fa-abacus"></i>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    <div class="fact">
                        <h2>3</h2>
                        <p>Анализ</p>
                        <i class="fal fa-user-chart"></i>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    <div class="fact">
                        <h2>4</h2>
                        <p>Экономия</p>
                        <i class="fal fa-hands-usd"></i>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Fact section end -->

    <!-- Review section -->
    <section class="review-section spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 push-8">
                    <img src="/img/quote.png" alt="" class="quote mb-5">
                    <carousel
                        :loop="true"
                        :nav="false"
                        :dots="false"
                        :items="1"
                        :margin="20"
                        :autoplay="true"
                        :mouseDrag="false"
                        :navText="navText"
                        :animateOut="'fadeOutDown'"
                        :animateIn="'fadeInDown'"
                        class="review-text-slider"
                    >
                        <div class="review-text">
                            <p>Классное приложение, приятный дизайн! Особенно нравится раздел "Долги", помогает держать всю информацию в одном месте и вовремя отдавать одолженные средства и кредиты :)</p>
                        </div>
                        <div class="review-text">
                            <p>Сервис реально помогает экономить! После первого месяца использования, выделил для себя несколько категорий, по которым в следующем месяце смог сократить расход в 2 раза!</p>
                        </div>
                        <div class="review-text">
                            <p>Просто и удобно! Отличное приложение, остановилась на нем после долгих поисков. Кроме своих счетов добавили с мужем общий счет, чтобы мониторить расход семейного бюджета.</p>
                        </div>
                    </carousel>
                </div>
                <div class="col-lg-4 pr-0 pull-3">
                    <carousel
                        :loop="true"
                        :nav="false"
                        :dots="true"
                        :items="3"
                        :center="true"
                        :margin="20"
                        :autoplay="true"
                        :mouseDrag="false"
                        class="review-meta-slider"
                    >
                        <div class="author-meta">
                            <div class="author-avatar set-bg" style="background-image: url('/img/review/1.jpg')"></div>
                            <div class="author-name">
                                <h4>Виталий Лизорский</h4>
                                <p>Веб-разработчик</p>
                            </div>
                        </div>
                        <div class="author-meta">
                            <div class="author-avatar set-bg" style="background-image: url('/img/review/2.jpg')"></div>
                            <div class="author-name">
                                <h4>Гарик Гладковский</h4>
                                <p>Графический дизайнер</p>
                            </div>
                        </div>
                        <div class="author-meta">
                            <div class="author-avatar set-bg" style="background-image: url('/img/review/3.jpg')"></div>
                            <div class="author-name">
                                <h4>Татьяна Щекина</h4>
                                <p>Product-менеджер</p>
                            </div>
                        </div>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
    <!-- Review section end -->


    <!-- Newsletter section -->
    <section class="newsletter-section gradient-bg">
        <div class="container text-white">
            <div class="row align-items-center">
                <div class="col-lg-7 newsletter-text">
                    <h2>Попробуйте совершенно Бесплатно!</h2>
                    <p>Зарегистрируйтесь, чтобы начать контролировать свои финансы и быть уверенным в завтрашнем дне</p>
                </div>
                <div class="col-lg-5 col-md-8 offset-lg-0 offset-md-2">
                    <div class="form">
                        <button @click="$router.push('/registration')" class="site-btn sb-gradients sbg-line">Зарегистрироваться</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Newsletter section end -->

    <section class="footer-section" id="footer">
        <div class="container">
            <div class="row spad justify-content-between">
                <div class="col-md-6 col-lg-3 footer-widget">
                    <h5 class="widget-title">По всем вопросам: </h5>
                    <p>
                        <a href="mailto:info@splitmyfunds.com" target="_blank"><i class="fal fa-at"></i>info@splitmyfunds.com</a>
                    </p>
                    <p><i class="fal fa-copyright"></i>2018 - {{ new Date().getFullYear() }}</p>
                </div>
                <div class="col-md-6 col-lg-2 footer-widget">
                    <h5 class="widget-title">Статьи:</h5>
                    <ul>
                        <li><a rel="nofollow" target="_blank" href="https://startpack.ru/application/splitmyfunds">Обзор SplitMyFunds</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://womo.ua/sposobyi-vedeniya-semeynogo-byudzheta/">Семейный бюджет</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://equity.today/kak-vesti-uchet-lichnyx-finansov.html">Учет, 10 правил</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://pamminvestment.org/arts.php?artcode=140">Требования успеха</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://equity.today/3-nadezhnyx-sposoba-vesti-uchet-lichnyx-finansov.html">3 способа учета</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-3 footer-widget">
                    <h5 class="widget-title">Полезно:</h5>
                    <ul>
                        <li><a href="/privacy-policy">Политика конфиденциальности</a></li>
                        <li><a href="/terms-of-service">Условия использования</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://dou.ua/forums/topic/22057/">Инструменты учета</a></li>
                        <li><a rel="nofollow" target="_blank" href="https://www.xe.com/currencyconverter/">Актуальный курс валют</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-3 footer-widget pl-3">
                    <h5 class="widget-title">Следите за новостями:</h5>
                    <div class="social">
                        <a rel="nofollow" target="_blank" href="https://facebook.com/splitmyfunds" class="facebook"><i class="fab fa-facebook-f"></i></a>
                        <a rel="nofollow" target="_blank" href="http://vk.com/splitmyfunds" class="vk"><i class="fab fa-vk"></i></a>
                        <!-- <a rel="nofollow" target="_blank" href="https://www.instagram.com/" class="instagram"><i class="fab fa-instagram"></i></a> -->
                        <a rel="nofollow" target="_blank" href="https://t.me/splitmyfunds" class="telegram"><i class="fab fa-telegram-plane"></i></a>
                    </div>
                    <div class="store-imgs">
                        <a href="https://play.google.com/store/apps/details?id=com.splitmyfunds.www" target="_blank">
                            <img src="/img/playstore.png" alt="android">
                        </a>
                        <img src="/img/appstore.png" alt="ios" @click="$router.push('/install-pwa-instructions')">
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
</template>

<script>
    import $ from 'jquery';
    import carousel from 'vue-owl-carousel';

    export default {
        name: 'Landing',
        components: { carousel },
        data() {
            return {
                navText: ['<i class="ti-angle-left"><i>', '<i class="ti-angle-right"><i>'],
            }
        },
        methods: {},
        mounted() {
            let $page = $('html, body');
            $('a[href*="#"]').click(function() {
                $page.animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 40
                }, 400);
                return false;
            });
        }
    }
</script>

<style lang="scss">
	@import './css/bootstrap.min.css';
    @import './css/animate.css';

    .owl-theme .owl-nav {
        text-align: left;
        .owl-prev, .owl-next {
            text-align: center;
        }
    }

    .review-text-slider .owl-prev,
    .review-text-slider .owl-next {
        width: 50px;
        height: 50px;
        background: #cbd3df !important;
        font-size: 24px !important;
        margin-right: 10px  !important;
        border-radius: 50% !important;
        padding: 12px 0 0 0 !important;
    }
</style>
<style scoped src="./css/style.css"></style>