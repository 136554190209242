<template>
    <div class="login">
        <i class="fal fa-times closeLogin" @click="$router.push('/')"></i>
        <form v-if="getPath === 'registration'" @submit.prevent="checkCredentials">
            <!-- <LoginSocial /> -->
            <label>
                Ваша почта:
                <input type="email" autocomplete="off" v-model="email">
            </label>
            <label>
                Пароль:
                <input type="password" autocomplete="off" v-model="password">
            </label>
            <label>
                Повторите пароль:
                <input type="password" autocomplete="off" v-model="password2">
            </label>
            <input @click="error = ''; $router.push('/login')" type="button" class="second" value="Вход">
            <input type="submit" class="main" value="Зарегистрироваться">
            <LoadingStatus
                status="Регистрация..."
                :loading="loading"
                :error="error"
            />
        </form>
        <form v-else-if="getPath === 'foregetpass'" @submit.prevent="forgetPassClick">
            <label>
                Ваша почта:
                <input type="email" autocomplete="on" v-model="email">
            </label>
            <input @click="error = ''; $router.push('/login')" type="button" class="second" value="Вход">
            <input type="submit" class="main" value="Восстановить">
            <LoadingStatus
                status="Проверка данных..."
                :loading="loading"
                :msg="msg"
                :error="error"
            />
        </form>
        <form v-else-if="getPath === 'restorepass'" @submit.prevent="restorePassClick">
            <label>
                Новый пароль:
                <input type="password" autocomplete="off" v-model="password">
            </label>
            <label>
                Повторите пароль:
                <input type="password" autocomplete="off" v-model="password2">
            </label>
            <input @click="error = ''; $router.push('/login')" type="button" class="second" value="Вход">
            <input type="submit" class="main" value="Восстановить">
            <LoadingStatus
                status="Проверка данных..."
                :loading="loading"
                :error="error"
            />
        </form>
        <form v-else-if="getPath === 'social-registration' && tokenId" @submit.prevent="checkCredentials">
            <label>
                {{ (queryEmail) ? "Ваша почта:" : "Остался один шаг:" }}
                <input placeholder="Ваш email" type="email" autocomplete="on" v-model="email">
            </label>
            <input @click="error = ''; $router.push('/')" type="button" class="second" value="Отмена">
            <input type="submit" class="main" value="Зарегистрироваться">
            <LoadingStatus
                status="Регистрация..."
                :loading="loading"
                :error="error"
            />
        </form>
        <form v-else @submit.prevent="checkCredentials">
            <!-- <LoginSocial /> -->
            <label>
                Ваша почта:
                <input type="email"  autocomplete="on" v-model="email">
            </label>
            <label>
                Пароль:
                <span @click="error = ''; $router.push('/foregetpass')">Забыли пароль?</span>
                <input type="password" autocomplete="on" v-model="password">
            </label>
            <input @click="error = ''; $router.push('/registration')" type="button" class="second" value="Регистрация">
            <input type="submit" class="main" value="Войти">
            <LoadingStatus
                status="Авторизация..."
                :loading="loading"
                :error="error"
            />
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import LoginSocial from './LoginSocial';
import LoadingStatus from './LoadingStatus';

export default {
    name: 'LogIn',
    components: {
        LoginSocial,
        LoadingStatus,
    },
    beforeMount() {
        if (this.isAuthenticated) this.$router.push('/');
    },
    created() {
        if (this.queryEmail) {
            this.email = this.queryEmail;
        } else if (this.codeId) {
            this.socialLogin();
        }
    },
    data() {
        return {
            email: '',
            password: '',
            password2: '',
            loading: false,
            error: '',
            msg: '',
        }
    },
    computed: {
        ...mapGetters(["currentUser", "isAuthenticated", "isDev"]),
        getPath() {
            return this.$route.name;
        },
        queryEmail() {
            return (this.$route.query.email === 'social') ? "" : this.$route.query.email;
        },
        tokenId() {
            return this.$route.query.id;
        },
        codeId() {
            return this.$route.query.code;
        },
    },
    methods: {
        ...mapActions(['logIn','register','loadExchangeRates','forgetPass','restorePass']),
        async checkCredentials() {
            this.error = '';

            let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !checkEmail.test(String(this.email).toLowerCase()) ) {
                this.error = 'введите существующую почту.';
                return 0;
            }

            if (!this.tokenId && get(this.password, 'length') < 6) {
                this.error = 'пароль должен состоять минимум из 6 символов.';
                return 0;
            }

            if (this.getPath === 'registration' && this.password !== this.password2) {
                this.error = 'пароли не совпадают.';
                return 0;
            }

            try {
                this.loading = true;
                if (this.getPath === 'registration') {
                    await this.register([ this.email, this.password, this.password2 ]);
                } else if (this.getPath === 'social-registration') {
                    await this.register([ this.email, null, null, this.tokenId ]);
                } else {
                    await this.logIn([ this.email, this.password]);
                }
                await this.loadExchangeRates();
                this.$router.push('/');
            } catch (error) {
                this.loading = false;
                this.error = error;
            }
        },
        async socialLogin() {
            try {
                this.loading = true;
                await this.logIn([ null, null, this.codeId]);
                await this.loadExchangeRates();
                this.$router.push('/');
            } catch (error) {
                this.loading = false;
                this.error = error;
            }
        },
        async forgetPassClick() {
            this.error = '';

            let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !checkEmail.test(String(this.email).toLowerCase()) ) {
                this.error = 'введите существующую почту.';
                return 0;
            }

            try {
                this.loading = true;
                await this.forgetPass(this.email);
                this.msg = 'На ваш e-mail отправлено письмо для восстановления пароля!';
            } catch (error) {
                this.error = error;
            }
            this.loading = false;
        },
        async restorePassClick() {
            this.error = '';

            if (get(this.password, 'length') < 6) {
                this.error = 'пароль должен состоять минимум из 6 символов.';
                return 0;
            }

            if (this.password !== this.password2) {
                this.error = 'пароли не совпадают.';
                return 0;
            }

            try {
                this.loading = true;
                await this.restorePass({ token: this.$route.params.token, newPassword: this.password });
                await this.loadExchangeRates();
                this.$router.push('/');
            } catch (error) {
                this.error = error;
            }
            this.loading = false;
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    .login {
        position: fixed;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        height: 100vh;
        width: 100%;
        background: rgba(243,247,249, 0.98);
        z-index: 999;
        overflow: auto;
    }

    .closeLogin {
        position: absolute;
        right: 25px;
        top: 25px;
        font-size: 38px;
        cursor: pointer;
    }

    form {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        margin: auto;
        max-width: 430px;
        width: 100%;
        padding: 25px 15px;
        input[type='button'], input[type='submit'] {
            margin: 15px 0 0 0;
        }
        input[type='text'], input[type='email'], input[type='password'] {
            width: 100%;
            margin-top: 10px;
            display: block;
        }
        label {
            text-align: left;
            width: 100%;
            margin-bottom: 15px;
            span {
                float: right;
                color: $main;
                cursor: pointer;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .closeLogin {
            right: 15px;
            top: 15px;
            font-size: 25px;
        }
        form {
            padding: 25px 25px;
            input[type='button'], input[type='submit'] {
                flex: 1 0 100%;
                max-width: 100%;
                &.main {
                    order: 1;
                }
                &.second {
                    order: 2;
                }
            }
        }
    }
</style>
