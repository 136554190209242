import getDb from './getDb.js';

export default {
    async getAccounts() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['accounts'],'readonly');
            trans.oncomplete = () => {
                resolve(accounts);
            };

            let store = trans.objectStore('accounts');
            let accounts = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    accounts.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    },
    async getSpending() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['spending'],'readonly');
            trans.oncomplete = () => {
                resolve(spending);
            };

            let store = trans.objectStore('spending');
            let spending = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    spending.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    },
    async getIncoming() {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['incoming'],'readonly');
            trans.oncomplete = () => {
                resolve(incoming);
            };

            let store = trans.objectStore('incoming');
            let incoming = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    incoming.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    },
    async saveAccounts(accounts) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['accounts'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('accounts');
            accounts.forEach(acc => {
                store.put(acc);
            });
        });
    },
    async saveSpending(spending) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['spending'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('spending');
            spending.forEach(spend => {
                store.put(spend);
            });
        });
    },
    async saveIncoming(incoming) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['incoming'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('incoming');
            incoming.forEach(income => {
                store.put(income);
            });
        });
    },
    async deleteAccount(id) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['accounts'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('accounts');
            if (id) {
                store.delete(Number(id));
            } else {
                store.clear();
            }
        });
    },
    async deleteSpend(id) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['spending'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('spending');
            if (id) {
                store.delete(Number(id));
            } else {
                store.clear();
            }
        });
    },
    async deleteIncome(id) {
        let db = await getDb();

        return new Promise(resolve => {
            let trans = db.transaction(['incoming'],'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('incoming');
            if (id) {
                store.delete(Number(id));
            } else {
                store.clear();
            }
        });
    }
}