<template>
  <div>
    <nav>
        <div>
            <a href="/" class="dashLink" @click.prevent="$emit('openDashClicked')">Счета</a>
            <router-link to="/">Операции</router-link>
            <router-link to="/debts">Долги</router-link>
            <label v-if="$route.name !== 'debts'" href="#" class="search" title="Поиск по комментариям">
                <i class="far fa-search"></i>
                <input
                    type="text"
                    v-model="searchStr"
                    @input="OPERATIONS_SEARCH(searchStr)"
                    placeholder="Поиск"
                >
                <i v-if="searchStr" @click="searchStr = ''; OPERATIONS_SEARCH('')" class="far fa-times"></i>
            </label>
        </div>
        <Calendar v-if="$route.name !== 'debts'" />
        <div v-else-if="getReturnedDebts.debts.length" class="returned" @click="SHOW_RETURNED()">
            <span v-if="!getReturnedDebts.showReturned">
                <i class="far fa-eye"></i><span class="isShowDesc"> Показать возвращенные</span>
            </span>
            <span v-else>
                <i class="far fa-eye-slash"></i><span class="isShowDesc"> Скрыть возвращенные</span>
            </span>
        </div>
    </nav>
    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
  </div>
</template>

<script>
import Calendar from '@/components/Calendar';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'home',
    components: { Calendar },
    data() {
        return {
            searchStr: '',
        }
    },
    computed: {
        ...mapGetters(["getReturnedDebts"]),
    },
    methods: {
        ...mapMutations(["OPERATIONS_SEARCH", "SHOW_RETURNED"])
    }
}
</script>

<style lang="scss">
    @import '@/assets/vars.scss';

    /* vue-scrollbar-live */
    $scrollbar-width: 6px;

    .scrollbar {
        border-radius: $scrollbar-width / 2 !important;
        background: $text-regular !important;
        transition: opacity 0.3s ease;
        cursor: pointer;
    }

    .scrollbar-y {
        left: 5px !important;
        width: $scrollbar-width !important;
    }

    .scrollbar-x {
        bottom: 0.25em !important;
        height: $scrollbar-width !important;
    }

    .scrollbar-wrap:hover .scrollbar {
        opacity: 0.4 !important;
    }

    /* vue-scrollbar-live end */
</style>

<style scoped lang="scss">
    @import '@/assets/forms.scss';
    @import '@/assets/home.scss';

    a.dashLink:hover, a.dashLink {
        border-bottom: none;
    }
</style>
