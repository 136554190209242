<template>
    <transition name="fade" mode="out-in">
        <p v-if="loading" :key="'sdf'">
            <HalfCircleSpinner :animation-duration="1000" :size="16" :color="'#3E2BCE'" />
            {{ status }}
        </p>
        <p v-if="msg" :key="'sddfgasd'">{{ msg }}</p>
        <p class="error" v-if="error" :key="'ewtr'">Ошибка: {{ error }}</p>
    </transition>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners';

export default {
    name: 'LoadingStatus',
    components: {
        HalfCircleSpinner,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            required: true,
        },
        msg: {
            type: [ String, Boolean ],
            default: false,
        },
        error: {
            type: [ String, Boolean ],
            default: false,
        },
    }
}
</script>

<style scoped lang="scss">
    p {
        position: absolute;
        margin: 0;
        bottom: -15px;
        .half-circle-spinner {
            display: inline-block;
            margin-bottom: -1px;
            margin-right: 7px;
        }
    }
</style>