import userFuncs from './userFuncs';
import categoriesFuncs from './categoriesFuncs';
import debtsFuncs from './debtsFuncs';
import operationsFuncs from './operationsFuncs';

export default {
    ...userFuncs,
    ...categoriesFuncs,
    ...debtsFuncs,
    ...operationsFuncs,
}