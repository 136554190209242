<template>
    <label class="login-social">
        <a class="login-social__button login-social__button--facebook" href="/api/user/login/facebook">
            <i class="fab fa-facebook-f"></i>
            Войти c Facebook
        </a>
         <a class="login-social__button login-social__button--vk" href="/api/user/login/vk">
            <i class="fab fa-vk"></i>
            Войти c Вконтакте
        </a>
        <p class="login-social__devider">
            <span>или</span>
        </p>
    </label>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'LoginSocial',
    data() {
        return {}
    },
    methods: {
        ...mapActions([ "logInFB" ]),
    },
    computed: {
        ...mapGetters(["isDev"]),
    },
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    .login-social {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
        margin-bottom: 0;

        &__devider {
            position: relative;
            flex: 1 0 100%;
            max-width: 100%;
            text-align: center;
            margin: 20px 0;
            border-bottom: 2px solid $border-color;

            span {
                background: $bg;
                position: absolute;
                top: 0;
                padding: 5px 10px;
                transform: translate(-50%, -50%);
            }
        }

        &__button {
            font-weight: 600;
            display: inline-block;
            padding: 15px 15px;
            color: #fff;
            text-decoration: none;
            text-align: center;
            border-radius: 5px;
            flex: 1 0 45%;
            max-width: 45%;

            &--facebook {
                background-color: #1877f2;
            }

            &--vk {
                background-color: #4a76a8;
            }

            &:active {
                opacity: 0.8;
            }
        }

        i {
            font-size: 14px;
            margin-right: 5px;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 410px) {
        .login-social__button {
            flex: 1 0 100%;
            max-width: 100%;
            margin-top: 10px;
        }
    }
</style>
